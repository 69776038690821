import { useEffect, useState } from 'react'
import { CircularProgress, Stack, Box } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { getAnalytics, logEvent } from 'firebase/analytics'
import useGetUserProject from '../../hooks/userData/useGetUserProject'
import { useAuth } from '../../contexts/AuthContext'
import { CompleteProjectInfo } from '../UpdateData/CompleteProjectInfo'
import { useGetCompanyInfo } from '../../hooks/projectData/useGetCompanyInfo'

const CompletionScalePage = () => {
    const { project, loading } = useGetUserProject();

    const { currentUser } = useAuth();
    const [pageLoading, setPageLoading] = useState(true);
    const analytics = getAnalytics();
    const navigate = useNavigate();

    useEffect(() => {
        if (loading === false) {
            console.log("Data. ", project.data)
            if (project.data.isActive != null && project.data.isActive !== true) {
                navigate("/inactive", { replace: true });
            }
            else if (project?.data?.needsCompletion == null || project?.data?.needsCompletion === false) {
                navigate("/home", { replace: true });
            }

            setPageLoading(false);
        }
    }, [loading, project])

    useEffect(() => {
        logEvent(analytics, 'page_view', {
            page_title: 'Completetion Scale ',
            page_path: window.location.pathname,
        });
    }, [])

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Stack justifyContent="center" alignItems="center" spacing={2} sx={{ marginTop: '50px' }}>
                {pageLoading ? (
                    <Box>
                        <CircularProgress />
                    </Box>
                ) : (
                    <>
                        {currentUser?.userInfo?.prefLang === 'swe' ? (
                            <>
                                <Box>
                                    <h5>Komplettera nödvändiga uppgifter</h5>
                                </Box>
                            </>
                        ) : (
                            <>
                                <Box>
                                    <h5>Complete the necessary information</h5>
                                </Box>
                            </>
                        )}

                        <CompleteProjectInfo projectData={project} />
                    </>
                )}

            </Stack>

        </Box>
    )
}

export default CompletionScalePage;