import { Box, CircularProgress, IconButton, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material"
import { GRADING_COLUMNS_TAGS, GRADING_TABLE_HEADERS } from "../../constants/grading"

import './css/Grading.css'
import { MatrixGradingRow } from "./MatrixGradingRow"
import { useEffect, useState } from "react"
import { EditCellDialog } from "./Components/EditCellDialog"
import { useGetGradingData } from "../../hooks/grading/useGetGradingData"
import { useGetGradingTrackConfigurations } from "../../hooks/grading/useGetGradingTrackConfigurations"
import { useGetGradingCellData } from "../../hooks/grading/useGetGradingCellData"

import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import ForwardRoundedIcon from '@mui/icons-material/ForwardRounded';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { addMilestoneDataCellFunc, addStartingLevelsFunc, addTargetLevelsFunc, markGradingCellAsCompletedFunc, verifiedMilestonesFunc } from "../../firebase/cloudFunctions"

/**
 * 
 * @param {object} param0 
 * @param {import("../../types/Project").IBOProject} param0.project
 * @param {boolean} param0.triggerUpdate
 */
export const MatrixGrading = ({ project, triggerUpdate }) => {
    const [isLoading, setIsLoading] = useState(false);
    const { loading: loadingConfig, trackConfigurations } = useGetGradingTrackConfigurations(project.id);
    const [openEditCell, setOpenEditCell] = useState(false);
    const [selectedCell, setSelectedCell] = useState({
        level: null,
        tag: null,
        levelData: null
    });
    console.log("ProjID: ", project?.id)

    const [levelData, setLevelData] = useState({
        'CR': {},
        'TeamR': {},
        'BR': {},
        'SR': {},
        'FR': {},
        'TechR': {},
        'IPRR': {},
    });

    const { loading: loadingCR, levels: levelsCR } = useGetGradingCellData(project.id, GRADING_COLUMNS_TAGS.CRTag);
    const { loading: loadingTeamR, levels: levelsTeamR } = useGetGradingCellData(project.id, GRADING_COLUMNS_TAGS.TeamRTag);
    const { loading: loadingBR, levels: levelsBR } = useGetGradingCellData(project.id, GRADING_COLUMNS_TAGS.BRTag);
    const { loading: loadingSR, levels: levelsSR } = useGetGradingCellData(project.id, GRADING_COLUMNS_TAGS.SRTag);
    const { loading: loadingFR, levels: levelsFR } = useGetGradingCellData(project.id, GRADING_COLUMNS_TAGS.FRTag);
    const { loading: loadingTechR, levels: levelsTechR } = useGetGradingCellData(project.id, GRADING_COLUMNS_TAGS.TechRTag);
    const { loading: loadingIPRR, levels: levelsIPRR } = useGetGradingCellData(project.id, GRADING_COLUMNS_TAGS.IPRR);

    const [currentTag, setCurrentTag] = useState('');
    const [currentLevel, setCurrentLevel] = useState(0);
    const [finalCell, setFinalCell] = useState(false);

    const [hasStartVal, setHasStartVal] = useState(false);
    const [hasTargetVal, setHasTargetVal] = useState(false);
    const [hasVerfifiedMilestones, setHasVerfifiedMilestones] = useState(false);
    const [startVal, setStartVal] = useState(Object);
    const [currentVal, setCurrentVal] = useState(Object);
    const [endVal, setEndVal] = useState(Object);
    const [update, setUpdate] = useState(false);
    const [verifyMilestones, setVerifyMilestones] = useState(false);

    const [isEditStart, setIsEditStart] = useState(false);
    const [isEditTarget, setIsEditTarget] = useState(false);

    const required = [
        "CR",
        "TeamR",
        "BR",
        "SR",
        "FR",
    ]


    const levels = [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
    ]

    useEffect(() => {
        if (loadingCR === false) {
            //console.log("CR: ", levelsCR)
            levelData[GRADING_COLUMNS_TAGS.CRTag] = levelsCR;
            const level = findCurrentLevel(GRADING_COLUMNS_TAGS.CRTag, levelsCR);
            //console.log("Level: ", GRADING_COLUMNS_TAGS.CRTag, level, currentVal)
            if (level > 0) {
                let localCurrent = currentVal;
                localCurrent[GRADING_COLUMNS_TAGS.CRTag] = level;
                setCurrentVal(localCurrent);
            }
            setUpdate(!update);
        }
    }, [loadingCR, levelsCR, triggerUpdate])

    useEffect(() => {
        if (loadingTeamR === false) {
            levelData[GRADING_COLUMNS_TAGS.TeamRTag] = levelsTeamR;
            const level = findCurrentLevel(GRADING_COLUMNS_TAGS.TeamRTag, levelsTeamR);
            //console.log("Level: ", GRADING_COLUMNS_TAGS.TeamRTag, level)
            if (level > 0) {
                let localCurrent = currentVal;
                localCurrent[GRADING_COLUMNS_TAGS.TeamRTag] = level;
                setCurrentVal(localCurrent);
            }
            setUpdate(!update);
        }
    }, [loadingTeamR, levelsTeamR, triggerUpdate])

    useEffect(() => {
        if (loadingBR === false) {
            levelData[GRADING_COLUMNS_TAGS.BRTag] = levelsBR;

            const level = findCurrentLevel(GRADING_COLUMNS_TAGS.BRTag, levelsBR);
            if (level > 0) {
                let localCurrent = currentVal;
                localCurrent[GRADING_COLUMNS_TAGS.BRTag] = level;
                setCurrentVal(localCurrent);
            }
            setUpdate(!update);
        }
    }, [loadingBR, levelsBR, triggerUpdate])

    useEffect(() => {
        if (loadingSR === false) {
            levelData[GRADING_COLUMNS_TAGS.SRTag] = levelsSR;

            const level = findCurrentLevel(GRADING_COLUMNS_TAGS.SRTag, levelsSR);
            if (level > 0) {
                let localCurrent = currentVal;
                localCurrent[GRADING_COLUMNS_TAGS.SRTag] = level;
                setCurrentVal(localCurrent);
                setUpdate(!update);
            }
        }
    }, [loadingSR, levelsSR, triggerUpdate])

    useEffect(() => {
        if (loadingFR === false) {
            levelData[GRADING_COLUMNS_TAGS.FRTag] = levelsFR;

            const level = findCurrentLevel(GRADING_COLUMNS_TAGS.FRTag, levelsFR);
            if (level > 0) {
                let localCurrent = currentVal;
                localCurrent[GRADING_COLUMNS_TAGS.FRTag] = level;
                setCurrentVal(localCurrent);
            }
            setUpdate(!update);
        }
    }, [loadingFR, levelsFR, triggerUpdate])

    useEffect(() => {
        if (loadingTechR === false) {
            levelData[GRADING_COLUMNS_TAGS.TechRTag] = levelsTechR;

            const level = findCurrentLevel(GRADING_COLUMNS_TAGS.TechRTag, levelsTechR);
            if (level > 0) {
                let localCurrent = currentVal;
                localCurrent[GRADING_COLUMNS_TAGS.TechRTag] = level;
                setCurrentVal(localCurrent);
            }
            setUpdate(!update);
        }
    }, [loadingTechR, levelsTechR, triggerUpdate])

    useEffect(() => {
        if (loadingIPRR === false) {
            levelData[GRADING_COLUMNS_TAGS.IPRR] = levelsIPRR;

            const level = findCurrentLevel(GRADING_COLUMNS_TAGS.IPRR, levelsIPRR);
            if (level > 0) {
                let localCurrent = currentVal;
                localCurrent[GRADING_COLUMNS_TAGS.IPRR] = level;
                setCurrentVal(localCurrent);
            }
            setUpdate(!update);
        }
    }, [loadingIPRR, levelsIPRR, triggerUpdate])

    useEffect(() => {
        if (loadingConfig === false) {
            let key = "START"
            if (project.activeTrack != null) {
                key = project.activeTrack.toLocaleUpperCase();
            }

            if (trackConfigurations[key] != null) {
                if (trackConfigurations[key]['startValues'] != null) {
                    setHasStartVal(true);
                    setStartVal(trackConfigurations[key]['startValues']);
                }

                if (trackConfigurations[key]['targetValues'] != null) {
                    setHasTargetVal(true);
                    setEndVal(trackConfigurations[key]['targetValues']);
                }

                if (trackConfigurations[key]['verifyMilestones'] != null && trackConfigurations[key]['verifyMilestones'] === true) {
                    setHasVerfifiedMilestones(true);
                }
            }

            setUpdate(!update);
        }
    }, [loadingConfig, trackConfigurations, triggerUpdate]);

    /*
    useEffect(() => {
        if (loading === false) {
            setCurrentVal(currentActive);
        }
    }, [loading]);*/

    const handleOpenCellDialog = (level, tag) => {
        if (hasStartVal === true) {
            setVerifyMilestones(false);
            let localLevel = null;

            if (levelData[tag] != null && levelData[tag][level] != null) {
                localLevel = levelData[tag][level];
            }
            setSelectedCell({
                level: level,
                tag: tag,
                levelData: localLevel,
            });

            setOpenEditCell(true);
        }

    }

    const handleCloseCellDialog = () => {
        setOpenEditCell(false);

        setSelectedCell({
            level: null,
            tag: null,
            levelData: null,
        });
    }


    /**
     * 
     * @param {*} level 
     * @param {*} tag 
     * @param {('start' | 'goal' | 'current')} state 
     */
    const setCellState = (level, tag, state) => {
        if (state === 'start') {
            let local = startVal;
            //console.log("START: ", local[tag], level)
            if (local[tag] === level) {
                local[tag] = null;
            }
            else {
                local[tag] = level;
            }

            setStartVal(local);

            if (endVal[tag] === level) {
                let localEnd = endVal;
                localEnd[tag] = null;
                setEndVal(localEnd);
            }
        }
        else if (state === 'goal') {
            let local = endVal;
            if (local[tag] === level) {
                local[tag] = null;
            }
            else {
                local[tag] = level;
            }

            setEndVal(local);

            if (startVal[tag] === level) {
                let localStart = startVal;
                localStart[tag] = null;
                setStartVal(localStart);
            }
        }
        else if (state === 'current') {
            let local = currentVal;
            local[tag] = level;
            setCurrentVal(local);
        }

        setUpdate(!update);
    }

    const isSaveDisabled = (isStart) => {
        let isDisabled = false;
        for (let index = 0; index < required.length; index++) {
            const tag = required[index];

            if (isStart) {
                if (startVal[tag] == null) {
                    isDisabled = true;
                }
            }
            else {
                if (endVal[tag] == null) {
                    isDisabled = true;
                }
            }
        }

        return isDisabled;
    }

    const resetStartInput = () => {
        setStartVal({});
        setIsEditStart(false);
    }

    const saveStartInput = () => {
        let track = "START";
        if (project.activeTrack != null && project.activeTrack !== "") {
            track = project.activeTrack.toLocaleUpperCase();
        }
        //console.log("STARTVAL: ", project.id, track, startVal)
        setIsLoading(true);
        addStartingLevelsFunc({
            projectID: project.id,
            track: track,
            startValues: startVal
        }).then(() => {
            setHasStartVal(true);
        }).catch((error) => {
            console.error("Error: ", error);
        }).finally(() => {
            setIsEditStart(false);
            setIsLoading(false);
        })
    }

    const resetTargetInput = () => {
        setEndVal({});
        setIsEditTarget(false);
    }

    const saveTargetInput = () => {
        let track = "START";
        if (project.activeTrack != null && project.activeTrack !== "") {
            track = project.activeTrack.toLocaleUpperCase();
        }
        console.log("STARTVAL: ", project.id, track, startVal)
        setIsLoading(true);
        addTargetLevelsFunc({
            projectID: project.id,
            track: track,
            targetValues: endVal
        }).then(() => {
            setHasTargetVal(true);
        }).catch((error) => {
            console.error("Error: ", error);
        }).finally(() => {
            setIsEditTarget(false);
            setIsLoading(false);
        });
    }

    const setStartInputVal = (level, tag) => {
        let local = startVal;
        //console.log("START: ", local[tag], level)
        if (local[tag] === level) {
            local[tag] = null;
        }
        else {
            local[tag] = level;
        }

        setStartVal(local);
        setUpdate(!update);
    }

    const setTargetInputVal = (level, tag) => {
        let local = endVal;
        if (local[tag] === level) {
            local[tag] = null;
        }
        else {
            local[tag] = level;
        }

        setEndVal(local);
        setUpdate(!update);
    }

    const handleOpenVerifyMilestones = () => {
        setVerifyMilestones(true);
        setFinalCell(false);
        let tag = 'CR';
        let level = startVal[tag];
        let localLevel = null;
        if (levelData[tag] != null && levelData[tag][level] != null) {
            localLevel = levelData[tag][level];
        }
        setCurrentTag(tag);
        setCurrentLevel(level);
        setSelectedCell({
            level: level,
            tag: tag,
            levelData: localLevel,
        });
        setOpenEditCell(true);
    }

    const handleNextyVerifyMilestones = () => {
        let tag = currentTag;
        let level = currentLevel;

        if (level === endVal[tag] || level === 9) {
            tag = nextTag(tag);

            if (tag == null) {
                let track = "START";
                if (project.activeTrack != null && project.activeTrack !== "") {
                    track = project.activeTrack.toLocaleUpperCase();
                }
                setIsLoading(true);
                verifiedMilestonesFunc({
                    projectID: project.id,
                    track: track,
                }).then(() => {
                    setHasVerfifiedMilestones(true);
                }).catch((error) => {
                    console.error("Error: ", error);
                }).finally(() => {
                    setIsLoading(false);
                })
                setOpenEditCell(false);

                return;
            }
            level = startVal[tag];
        }
        else {
            level = level + 1;
        }

        if (level === endVal[tag] || level === 9) {
            let nextStepTag = nextTag(tag);

            if (nextStepTag == null || (startVal[nextStepTag] == null && endVal[nextStepTag] == null)) {
                setFinalCell(true);
            }
        }

        let localLevel = null;
        if (levelData[tag] != null && levelData[tag][level] != null) {
            localLevel = levelData[tag][level];
        }

        setCurrentTag(tag);
        setCurrentLevel(level);
        //console.log("Values: ", tag, level, localLevel, levelData)
        setSelectedCell({
            level: level,
            tag: tag,
            levelData: localLevel,
        });
        setOpenEditCell(true);
    }

    const handleSaveMilestoneData = (tag, level, milestones) => {
        let localLevelData = {};
        if (levelData[tag] != null && levelData[tag][level] != null)
            localLevelData = levelData[tag][level];

        //console.log("Save milestonedata: ", localLevelData, milestones)

        let milestonesText = [];
        let milestonesChecked = [];

        for (let index = 0; index < milestones.length; index++) {
            const milestone = milestones[index];

            milestonesText.push(milestone.text);
            milestonesChecked.push(milestone.checked);
        }

        localLevelData['Milestones'] = milestonesText;
        localLevelData['MilestoneCompletion'] = milestonesChecked;
        levelData[tag][level] = localLevelData;

        addMilestoneDataCellFunc({
            projectID: project.id,
            columnTag: tag,
            level: level,
            levelData: localLevelData,
        }).then(() => {
            console.log("Saved: ", tag + " - " + level);
        }).catch((error) => {
            console.error("Error saving: ", tag + " - " + level, "Error: ", error);
        });
    }

    const handleMarkCellAsComplete = (tag, level) => {
        let track = "START";
        if (project.activeTrack != null && project.activeTrack !== "") {
            track = project.activeTrack.toLocaleUpperCase();
        }

        markGradingCellAsCompletedFunc({
            projectID: project.id,
            columnTag: tag,
            level: level,
            track: track,
        }).then(() => {
            console.log("Set!")
        }).catch((error) => {
            console.error("Error: ", error)
        })
    }

    const nextTag = (currentTag) => {
        switch (currentTag) {
            case GRADING_COLUMNS_TAGS.CRTag:
                return GRADING_COLUMNS_TAGS.TeamRTag;
            case GRADING_COLUMNS_TAGS.TeamRTag:
                return GRADING_COLUMNS_TAGS.BRTag;
            case GRADING_COLUMNS_TAGS.BRTag:
                return GRADING_COLUMNS_TAGS.SRTag;
            case GRADING_COLUMNS_TAGS.SRTag:
                return GRADING_COLUMNS_TAGS.FRTag;
            case GRADING_COLUMNS_TAGS.FRTag:
                return GRADING_COLUMNS_TAGS.TechRTag;
            case GRADING_COLUMNS_TAGS.TechRTag:
                return GRADING_COLUMNS_TAGS.IPRR;
            case GRADING_COLUMNS_TAGS.IPRR:
                return null;
            default:
                return null;
        }
    }

    const findCurrentLevel = (tag, columnData) => {
        //console.log(tag, "=>", columnData);
        let curLevel = -1;
        if (columnData != null) {
            let maxlevel = -1;
            for (const key in columnData) {
                if (Object.hasOwnProperty.call(columnData, key)) {
                    const level = Number(key);
                    const values = columnData[key];
                    if (values?.LevelCompletion == null && values?.LevelStart == null && (curLevel < 0 || level < curLevel) && level >= startVal[tag]) {
                        curLevel = level;
                    }
                    else if (values?.LevelCompletion != null || values?.LevelStart != null) {
                        //console.log("Hello?")
                        if (level > maxlevel) {
                            maxlevel = level;
                        }
                    }
                    //console.log(level, "=>", values);
                }
            }

            if (curLevel === -1 && maxlevel !== -1) {
                curLevel = maxlevel + 1;
            }
        }

        return curLevel;
    }

    return (
        <>
            <Box sx={{ position: 'absolute', marginTop: '-25px', marginLeft: '5px' }}>
                {hasStartVal !== true ? (
                    <Stack justifyContent={'center'} alignItems={'center'} spacing={1}>
                        <Stack sx={{ width: '550px', height: '25px' }} alignItems={'center'} direction="row" spacing={1}>
                            <Typography fontWeight={700}>
                                Define the IBO Readiness level before participation in {project.activeTrack ? project.activeTrack.toLocaleUpperCase() : "START"}
                            </Typography>
                            <Box width={'26px'}>
                                {!hasStartVal ? (
                                    <>
                                        {isEditStart ? (
                                            <Stack direction={'row'}>
                                                <IconButton size="small" disabled={isSaveDisabled(true) || isLoading} onClick={saveStartInput}>
                                                    {isLoading ? (
                                                        <CircularProgress size={19} />
                                                    ) : (
                                                        <SaveRoundedIcon htmlColor={isSaveDisabled(true) ? "#cccccc" : "#4eacc1"} />
                                                    )}
                                                </IconButton>
                                                <IconButton size="small" disabled={isLoading} onClick={resetStartInput}>
                                                    <CancelRoundedIcon htmlColor="#ae3737" />
                                                </IconButton>
                                            </Stack>
                                        ) : (
                                            <IconButton size="small" onClick={() => setIsEditStart(true)}>
                                                <ForwardRoundedIcon htmlColor="#4eacc1" />
                                            </IconButton>
                                        )}
                                    </>
                                ) : ("")}
                            </Box>
                        </Stack>
                    </Stack>
                ) : ("")}
            </Box>

            <Table className="grade-table" id="table">
                <TableBody>
                    <TableHead className="grade-table">
                        <TableRow>
                            <TableCell className="grade-table grade-table-header">
                                {GRADING_TABLE_HEADERS['Level']}
                            </TableCell>
                            <TableCell className="grade-table grade-table-header">
                                {GRADING_TABLE_HEADERS[GRADING_COLUMNS_TAGS.CRTag]}
                            </TableCell>
                            <TableCell className="grade-table grade-table-header">
                                {GRADING_TABLE_HEADERS[GRADING_COLUMNS_TAGS.TeamRTag]}
                            </TableCell>
                            <TableCell className="grade-table grade-table-header">
                                {GRADING_TABLE_HEADERS[GRADING_COLUMNS_TAGS.BRTag]}
                            </TableCell>
                            <TableCell className="grade-table grade-table-header">
                                {GRADING_TABLE_HEADERS[GRADING_COLUMNS_TAGS.SRTag]}
                            </TableCell>
                            <TableCell className="grade-table grade-table-header">
                                {GRADING_TABLE_HEADERS[GRADING_COLUMNS_TAGS.FRTag]}
                            </TableCell>
                            <TableCell className="grade-table grade-table-header">
                                {GRADING_TABLE_HEADERS[GRADING_COLUMNS_TAGS.TechRTag]} (OPTIONAL)
                            </TableCell>
                            <TableCell className="grade-table grade-table-header">
                                {GRADING_TABLE_HEADERS[GRADING_COLUMNS_TAGS.IPRR]} (OPTIONAL)
                            </TableCell>
                        </TableRow>

                    </TableHead>
                    <TableBody>
                        {levels.map((level) => {
                            return (
                                <MatrixGradingRow
                                    key={'grading-' + level}
                                    level={level}
                                    openCellDialog={handleOpenCellDialog}
                                    setStartInputVal={setStartInputVal}
                                    setTargetInputVal={setTargetInputVal}
                                    isEditStart={isEditStart}
                                    isEditTarget={isEditTarget}
                                    startLevel={startVal}
                                    endLevel={endVal}
                                    currentLevel={currentVal}
                                    levelsData={levelData}
                                    selectedCell={selectedCell}
                                    update={update}
                                />
                            )
                        })}
                    </TableBody>

                </TableBody>
            </Table>
            {/* 
            <Box paddingY={'20px'}>
                <Stack justifyContent={'center'} alignItems={'center'} spacing={1}>
                    <Stack sx={{ width: '550px', height: '25px' }} alignItems={'center'} direction="row" spacing={1}>
                        <Box width={'26px'}>
                            {hasStartVal ? (
                                <CheckRoundedIcon htmlColor="#00ad03" />
                            ) : ("")}
                        </Box>
                        <Typography fontWeight={700}>
                            Define the IBO Readiness level before participation in {project.activeTrack ? project.activeTrack.toLocaleUpperCase() : "START"}
                        </Typography>
                        <Box width={'26px'}>
                            {!hasStartVal ? (
                                <>
                                    {isEditStart ? (
                                        <Stack direction={'row'}>
                                            <IconButton size="small" disabled={isSaveDisabled(true) || isLoading} onClick={saveStartInput}>
                                                {isLoading ? (
                                                    <CircularProgress size={19} />
                                                ) : (
                                                    <SaveRoundedIcon htmlColor={isSaveDisabled(true) ? "#cccccc" : "#4eacc1"} />
                                                )}
                                            </IconButton>
                                            <IconButton size="small" disabled={isLoading} onClick={resetStartInput}>
                                                <CancelRoundedIcon htmlColor="#ae3737" />
                                            </IconButton>
                                        </Stack>
                                    ) : (
                                        <IconButton size="small" onClick={() => setIsEditStart(true)}>
                                            <ForwardRoundedIcon htmlColor="#4eacc1" />
                                        </IconButton>
                                    )}
                                </>
                            ) : ("")}
                        </Box>
                    </Stack>

                    <Stack sx={{ width: '550px', height: '25px', color: !hasStartVal ? "#bdbdbd" : "", fontStyle: !hasStartVal ? "italic" : "normal" }} alignItems={'center'} direction="row" spacing={1}>
                        <Box width={'26px'}>
                            {hasTargetVal ? (
                                <CheckRoundedIcon htmlColor="#00ad03" />
                            ) : ("")}
                        </Box>
                        <Typography fontWeight={700}>
                            Define the target IBO Readiness level after participation in {project.activeTrack ? project.activeTrack.toLocaleUpperCase() : "START"}
                        </Typography>
                        <Box width={'26px'}>
                            {!hasTargetVal && hasStartVal ? (
                                <>
                                    {isEditTarget ? (
                                        <Stack direction={'row'}>
                                            <IconButton size="small" disabled={isSaveDisabled(false) || isLoading} onClick={saveTargetInput}>
                                                {isLoading ? (
                                                    <CircularProgress size={19} />
                                                ) : (
                                                    <SaveRoundedIcon htmlColor={isSaveDisabled(true) ? "#cccccc" : "#4eacc1"} />
                                                )}
                                            </IconButton>
                                            <IconButton size="small" disabled={isLoading} onClick={resetTargetInput}>
                                                <CancelRoundedIcon htmlColor="#ae3737" />
                                            </IconButton>
                                        </Stack>
                                    ) : (
                                        <IconButton size="small" onClick={() => setIsEditTarget(true)}>
                                            <ForwardRoundedIcon htmlColor="#4eacc1" />
                                        </IconButton>
                                    )}
                                </>
                            ) : ("")}
                        </Box>
                    </Stack>
                    <Stack sx={{ width: '550px', height: '25px', color: !hasTargetVal ? "#bdbdbd" : "", fontStyle: !hasTargetVal ? "italic" : "normal" }} alignItems={'center'} direction="row" spacing={1}>
                        <Box width={'26px'}>
                            {hasVerfifiedMilestones ? (
                                <CheckRoundedIcon htmlColor="#00ad03" />
                            ) : ("")}
                        </Box>
                        <Typography fontWeight={700}>
                            Verify the preliminary milestones for the participation in {project.activeTrack ? project.activeTrack.toLocaleUpperCase() : "START"}
                        </Typography>
                        <Box width={'26px'}>
                            {!hasVerfifiedMilestones && hasTargetVal ? (
                                <IconButton size="small" disabled={isLoading} onClick={handleOpenVerifyMilestones}>
                                    {isLoading ? (
                                        <CircularProgress size={19} />
                                    ) : (
                                        <ForwardRoundedIcon htmlColor="#4eacc1" />
                                    )}
                                </IconButton>
                            ) : ("")}
                        </Box>
                    </Stack>
                </Stack>
            </Box>*/}

            <EditCellDialog
                open={openEditCell}
                handleClose={handleCloseCellDialog}
                cell={selectedCell}
                startLevel={startVal}
                targetLevel={endVal}
                currentLevel={currentVal}
                project={project}
                setCellState={setCellState}
                hasStartVal={hasStartVal}
                hasTargetVal={hasTargetVal}
                hasVerfifiedMilestones={hasVerfifiedMilestones}
                verifyMilestones={verifyMilestones}
                handleSaveMilestoneData={handleSaveMilestoneData}
                handleNextyVerifyMilestones={handleNextyVerifyMilestones}
                handleMarkCellAsComplete={handleMarkCellAsComplete}
                finalCell={finalCell}
                update={update}
            />
        </>

    )
}