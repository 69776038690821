import { Box, Divider, Grid, Stack } from "@mui/material"
import { useAuth } from "../../../contexts/AuthContext";
import { useMemo } from "react";
import { ExtendedCompanyQuestionsEng, ExtendedCompanyQuestionsSwe } from "../../../constants/companyQuestions/companyQuestions";
import { addThousandsSeparator } from "../../Extrafunctions/addThousandSeparator";


export const CompanyStateAidDisplayComponent = ({ answerData, listVariant = false  }) => {
    const { currentUser } = useAuth();

    const CompStateAidQuestion = useMemo(() => {
        if (currentUser?.userInfo?.prefLang === 'swe') {
            return ExtendedCompanyQuestionsSwe.compStateAidValue;
        }
        else {
            return ExtendedCompanyQuestionsEng.compStateAidValue;
        }
    }, [currentUser]);

    const calcTotal = () => {
        let totalSum = 0;

        for (let index = 0; index < answerData.length; index++) {
            const element = answerData[index];
            totalSum += element.amount;
        }

        return totalSum
    }

    const displayDate = (date) => {
        if (date != null) {
            let dateObj = new Date(date);

            return dateObj.toLocaleDateString();
        }

        return date
    }

    return (
        <Box sx={listVariant ? { paddingX: '15px', paddingTop: '10px'} : {}}>
            <Grid container direction={'row'} spacing={1}>
                <Grid item xs={3}>
                    <Box fontWeight={700}>{CompStateAidQuestion.dateLabel}</Box>
                </Grid>
                <Grid item xs={6}>
                    <Box fontWeight={700}>{CompStateAidQuestion.grantOrgLabel}</Box>
                </Grid>
                <Grid item xs={3}>
                    <Box fontWeight={700}>{CompStateAidQuestion.amountLabel}</Box>
                </Grid>
            </Grid>
            <Divider className="thick blue" sx={{ marginTop: '3px' }} />
            {answerData?.map((item, index) => (
                <Box key={index} marginBottom={'3px'}>
                    <Grid container direction={'row'} spacing={1}>
                        <Grid item xs={3}>
                            <Box>{displayDate(item.date)}</Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box>{item.grantingOrg}</Box>
                        </Grid>
                        <Grid item xs={3}>
                            <Box>{addThousandsSeparator(item.amount)} EUR</Box>
                        </Grid>
                    </Grid>
                    <Divider sx={{ marginTop: '3px' }} />
                </Box>
            ))}
            <Divider className="thick blue" sx={{ marginTop: '3px' }} />
            <Grid container direction={'row'} spacing={1}>
                <Grid item xs={3}>
                    <Box></Box>
                </Grid>
                <Grid item xs={6}>
                    <Box sx={{display: 'flex', justifyContent: 'flex-end'}}><strong>Total:</strong></Box>
                </Grid>
                <Grid item xs={3}>
                    <Box> {addThousandsSeparator(calcTotal())} EUR</Box>
                </Grid>
            </Grid>
        </Box>
    )
}