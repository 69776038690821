import { doc, getDoc } from 'firebase/firestore';
import { useState, useEffect } from 'react'
import { db } from '../../firebase'

export const useGetExternalCommentData = (projectID, commentID) => {
    const [comment, setComment] = useState(Object);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (projectID == null || commentID == null || projectID === "" || commentID === "") return;

        let fsQuery = doc(db, "Projects/" + projectID + "/ExternalNotes/" + commentID);

        getDoc(fsQuery).then((docSnap) => {
            setComment({ id: docSnap.id, ...docSnap.data() });
        }).catch((error) => {
            console.error("Error getting document:", error);
        }).finally(() => {
            setIsLoading(false);
        });

    }, [projectID, commentID])

    return { comment, isLoading };
};