import { Box, TableCell, TableRow, Tooltip } from "@mui/material";
import { GRADING_CELL_INFOTEXT } from "../../constants/lang/eng/grading";
import { GRADING_CELL_BACKGROUND, GRADING_CELL_LEVEL_COLOR, GRADING_COLUMNS_TAGS } from "../../constants/grading";

import StartRoundedIcon from '@mui/icons-material/StartRounded';
import SportsScoreRoundedIcon from '@mui/icons-material/SportsScoreRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';

export const MatrixGradingRow = ({ level, openCellDialog, startLevel, endLevel, currentLevel, levelsData, isEditStart, isEditTarget, setStartInputVal, setTargetInputVal, selectedCell, update }) => {
    const setCellClassName = (tag) => {
        let className = "grade-table " + GRADING_CELL_BACKGROUND[tag][level];

        if (isOutOfRange(tag)) {
            className += " out-of-range-cell";
        }

        //console.log("Current: ", currentLevel, currentLevel[tag])
        if (currentLevel[tag] != null && currentLevel[tag] === Number(level)) {
            className += " start-cell"
        }

        if (selectedCell.tag === tag && selectedCell.level === level) {
            className += " current-selected"
        }

        return className;
    }

    const setStartIcon = (tag) => {
        if (startLevel[tag] != null && startLevel[tag] === Number(level)) {
            return (
                <StartRoundedIcon sx={{ position: 'absolute', marginLeft: '-8px', marginTop: '-7px', height: '18px' }} />
            )
        }
    }

    const setEndIcon = (tag) => {
        if (endLevel[tag] != null && endLevel[tag] === Number(level)) {
            return (
                <SportsScoreRoundedIcon sx={{ position: 'absolute', marginLeft: '-8px', marginTop: '-7px', height: '18px' }} />
            )
        }
    }

    const handleClickOnCell = (tag) => {
        if (isEditStart) {
            setStartInputVal(level, tag);
        }
        else if (isEditTarget) {
            setTargetInputVal(level, tag);
        }
        else {
            openCellDialog(level, tag);
        }

    }

    const isOutOfRange = (tag) => {
        if ((startLevel[tag] != null && startLevel[tag] > Number(level)) || (endLevel[tag] != null && endLevel[tag] < Number(level))) {
            return true;
        }
        else {
            return false;
        }
    }

    const isCompleted = (tag) => {
        let completed = false;
        let timestamp = "";
        let track = "";
        if (levelsData[tag] != null && levelsData[tag][level] != null) {
            if (levelsData[tag][level].LevelCompletion != null) {
                completed = true;
                if (levelsData[tag][level].LevelCompletion.CompletionTime != null) {
                    timestamp = levelsData[tag][level].LevelCompletion.CompletionTime.toDate().toLocaleDateString('se-SV')
                }
                if (levelsData[tag][level].LevelCompletion.InTrack != null) {
                    track = levelsData[tag][level].LevelCompletion.InTrack;
                }
            }
        }

        if (completed) {
            //console.log('Time: ', timestamp)
            let text = "Completed";
            if (track != null && track !== "") {
                text += " in " + track;
            }

            if (timestamp != null && timestamp !== "") {
                // @ts-ignore
                text += " at " + timestamp;
            }
            return (
                <Tooltip title={text} arrow>
                    <CheckRoundedIcon sx={{ color: '#00ad03', position: 'absolute', marginRight: '-10px', marginTop: '-7px', height: '18px' }} />
                </Tooltip>
            )
        }
        else {
            return "";
        }
    }

    return (
        <TableRow>
            <TableCell className="grade-table" style={{ backgroundColor: GRADING_CELL_LEVEL_COLOR[level], textAlign: "center" }}>
                {level}
            </TableCell>
            <TableCell className={setCellClassName(GRADING_COLUMNS_TAGS.CRTag)} sx={{ cursor: isOutOfRange(GRADING_COLUMNS_TAGS.CRTag) ? "default" : "pointer" }} onClick={() => { handleClickOnCell(GRADING_COLUMNS_TAGS.CRTag) }}>
                <Box display={'flex'} justifyContent={'end'}>
                    {isCompleted(GRADING_COLUMNS_TAGS.CRTag)}
                </Box>
                <Box display={'flex'} justifyContent={'start'}>
                    {setStartIcon(GRADING_COLUMNS_TAGS.CRTag)}
                    {setEndIcon(GRADING_COLUMNS_TAGS.CRTag)}

                    <Box minHeight={'70px'} display={'flex'} alignItems={'center'}>
                        <Box>
                            {GRADING_CELL_INFOTEXT[GRADING_COLUMNS_TAGS.CRTag][level]}
                        </Box>
                    </Box>
                </Box>
            </TableCell>
            <TableCell className={setCellClassName(GRADING_COLUMNS_TAGS.TeamRTag)} sx={{ cursor: isOutOfRange(GRADING_COLUMNS_TAGS.TeamRTag) ? "default" : "pointer" }} onClick={() => { handleClickOnCell(GRADING_COLUMNS_TAGS.TeamRTag) }}>
                <Box display={'flex'} justifyContent={'end'}>
                    {isCompleted(GRADING_COLUMNS_TAGS.TeamRTag)}
                </Box>
                <Box display={'flex'} justifyContent={'start'}>
                    {setStartIcon(GRADING_COLUMNS_TAGS.TeamRTag)}
                    {setEndIcon(GRADING_COLUMNS_TAGS.TeamRTag)}
                    <Box minHeight={'70px'} display={'flex'} alignItems={'center'}>
                        <Box>
                            {GRADING_CELL_INFOTEXT[GRADING_COLUMNS_TAGS.TeamRTag][level]}
                        </Box>
                    </Box>
                </Box>
            </TableCell>
            <TableCell className={setCellClassName(GRADING_COLUMNS_TAGS.BRTag)} sx={{ cursor: isOutOfRange(GRADING_COLUMNS_TAGS.BRTag) ? "default" : "pointer" }} onClick={() => { handleClickOnCell(GRADING_COLUMNS_TAGS.BRTag) }}>
                <Box display={'flex'} justifyContent={'end'}>
                    {isCompleted(GRADING_COLUMNS_TAGS.BRTag)}
                </Box>
                <Box display={'flex'} justifyContent={'start'}>
                    {setStartIcon(GRADING_COLUMNS_TAGS.BRTag)}
                    {setEndIcon(GRADING_COLUMNS_TAGS.BRTag)}

                    <Box minHeight={'70px'} display={'flex'} alignItems={'center'}>
                        <Box>
                            {GRADING_CELL_INFOTEXT[GRADING_COLUMNS_TAGS.BRTag][level]}
                        </Box>
                    </Box>
                </Box>

            </TableCell>
            <TableCell className={setCellClassName(GRADING_COLUMNS_TAGS.SRTag)} sx={{ cursor: isOutOfRange(GRADING_COLUMNS_TAGS.SRTag) ? "default" : "pointer" }} onClick={() => { handleClickOnCell(GRADING_COLUMNS_TAGS.SRTag) }}>
                <Box display={'flex'} justifyContent={'end'}>
                    {isCompleted(GRADING_COLUMNS_TAGS.SRTag)}
                </Box>
                <Box display={'flex'} justifyContent={'start'}>
                    {setStartIcon(GRADING_COLUMNS_TAGS.SRTag)}
                    {setEndIcon(GRADING_COLUMNS_TAGS.SRTag)}
                    <Box minHeight={'70px'} display={'flex'} alignItems={'center'}>
                        <Box>
                            {GRADING_CELL_INFOTEXT[GRADING_COLUMNS_TAGS.SRTag][level]}
                        </Box>
                    </Box>
                </Box>
            </TableCell>
            <TableCell className={setCellClassName(GRADING_COLUMNS_TAGS.FRTag)} sx={{ cursor: isOutOfRange(GRADING_COLUMNS_TAGS.FRTag) ? "default" : "pointer" }} onClick={() => { handleClickOnCell(GRADING_COLUMNS_TAGS.FRTag) }}>
                <Box display={'flex'} justifyContent={'end'}>
                    {isCompleted(GRADING_COLUMNS_TAGS.FRTag)}
                </Box>
                <Box display={'flex'} justifyContent={'start'}>
                    {setStartIcon(GRADING_COLUMNS_TAGS.FRTag)}
                    {setEndIcon(GRADING_COLUMNS_TAGS.FRTag)}
                    <Box minHeight={'70px'} display={'flex'} alignItems={'center'}>
                        <Box>
                            {GRADING_CELL_INFOTEXT[GRADING_COLUMNS_TAGS.FRTag][level]}
                        </Box>
                    </Box>
                </Box>
            </TableCell>
            <TableCell className={setCellClassName(GRADING_COLUMNS_TAGS.TechRTag)} sx={{ cursor: isOutOfRange(GRADING_COLUMNS_TAGS.TechRTag) ? "default" : "pointer" }} onClick={() => { handleClickOnCell(GRADING_COLUMNS_TAGS.TechRTag) }}>
                <Box display={'flex'} justifyContent={'end'}>
                    {isCompleted(GRADING_COLUMNS_TAGS.TechRTag)}
                </Box>
                <Box display={'flex'} justifyContent={'start'}>
                    {setStartIcon(GRADING_COLUMNS_TAGS.TechRTag)}
                    {setEndIcon(GRADING_COLUMNS_TAGS.TechRTag)}
                    <Box width={'160px'} minHeight={'70px'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                        <Box>
                            {GRADING_CELL_INFOTEXT[GRADING_COLUMNS_TAGS.TechRTag][level]}
                        </Box>
                    </Box>
                </Box>
            </TableCell>
            <TableCell className={setCellClassName(GRADING_COLUMNS_TAGS.IPRR)} sx={{ cursor: isOutOfRange(GRADING_COLUMNS_TAGS.IPRR) ? "default" : "pointer" }} onClick={() => { handleClickOnCell(GRADING_COLUMNS_TAGS.IPRR) }}>
                <Box display={'flex'} justifyContent={'end'}>
                    {isCompleted(GRADING_COLUMNS_TAGS.IPRR)}
                </Box>
                <Box display={'flex'} justifyContent={'start'}>
                    {setStartIcon(GRADING_COLUMNS_TAGS.IPRR)}
                    {setEndIcon(GRADING_COLUMNS_TAGS.IPRR)}
                    <Box minHeight={'70px'} display={'flex'} alignItems={'center'}>
                        <Box>
                            {GRADING_CELL_INFOTEXT[GRADING_COLUMNS_TAGS.IPRR][level]}
                        </Box>
                    </Box>
                </Box>
            </TableCell>
        </TableRow>
    );
}