import { FormControl, Grid, IconButton, InputAdornment, OutlinedInput, Stack, TextField } from "@mui/material"
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useAuth } from "../../../contexts/AuthContext";
import { useMemo } from "react";
import { ExtendedCompanyQuestionsEng, ExtendedCompanyQuestionsSwe } from "../../../constants/companyQuestions/companyQuestions";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import 'dayjs/locale/sv';
import { addThousandsSeparator, removeNonNumeric } from "../../Extrafunctions/addThousandSeparator";

export const CompanyStateAidRow = ({ item, index, handleChangeDate, handleChangeGrantOrg, handleChangeAmount, deleteRow, isOwnershipInvalid }) => {
    const { currentUser } = useAuth();

    const CompStateAidQuestion = useMemo(() => {
        if (currentUser?.userInfo?.prefLang === 'swe') {
            return ExtendedCompanyQuestionsSwe.compStateAidValue;
        }
        else {
            return ExtendedCompanyQuestionsEng.compStateAidValue;
        }
    }, [currentUser]);


    return (
        <Grid container spacing={0.5} sx={{ width: '100%', mb: '5px' }} justifyContent={'space-between'} alignItems={'center'} direction={'row'}>
            <Grid item xs={3.5}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="sv">
                    <DatePicker
                        label={CompStateAidQuestion.dateLabel}
                        value={dayjs(item?.date || null)}
                        disableFuture
                        slotProps={{ textField: { size: 'small', error: isOwnershipInvalid && (item?.date == null || item?.date === "")}  }}
                        onChange={(e) => handleChangeDate(index, e.toDate())}
                    />
                </LocalizationProvider>
            </Grid>

            <Grid item xs={5.5}>
                <TextField
                    fullWidth
                    label={CompStateAidQuestion.grantOrgLabel}
                    required
                    size="small"
                    value={item?.grantingOrg || ""}
                    onChange={(e) => handleChangeGrantOrg(index, e.target.value)}
                    error={isOwnershipInvalid && (item?.grantingOrg == null || item?.grantingOrg === "")}
                />
            </Grid>

            <Grid item xs={3}>
                <Stack>
                    <FormControl required fullWidth>
                        <OutlinedInput
                            endAdornment={<InputAdornment position="end">EUR</InputAdornment>}
                            size="small"
                            inputProps={{
                                min: 0,
                            }}
                            sx={{ height: '40px' }}
                            value={addThousandsSeparator(item?.amount) || 0}
                            onChange={(e) => handleChangeAmount(index, removeNonNumeric(e.target.value))}
                        />
                    </FormControl>
                </Stack>
            </Grid>

            <Grid item xs={0.5}>
                {index > 0 ? (
                    <IconButton onClick={deleteRow} sx={{ height: '22px', width: '22px' }} size="small">
                        <HighlightOffIcon sx={{ height: '22px' }} htmlColor="#ac2020" />
                    </IconButton>
                ) : (
                    <div style={{ width: '25px' }}></div>
                )}
            </Grid>
        </Grid>
    )
}