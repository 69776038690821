import { TableCell, TableRow } from "@mui/material";
import '../css/Grading.css'

import { GRADING_CELL_INFOTEXT } from "../../../constants/lang/eng/grading";
import { GRADING_CELL_BACKGROUND, GRADING_CELL_LEVEL_COLOR, GRADING_COLUMNS_TAGS } from "../../../constants/grading";


/**
 * 
 * @param {object} param0 
 * @param {number} param0.level
 * @param {function(string, number):void} param0.setRating
 * @param {object} param0.btnClasses
 * @param {object} param0.btnSavedClasses
 * @param {boolean} param0.isEditable
 * @returns 
 */
export const GradingRow = ({ level, setRating, btnClasses, btnSavedClasses, isEditable }) => {

    const setCellClassName = (tag) => {
        let className = "";
        if (btnClasses[tag] === level) {
            className += "btnClass clicked";
        }
        else {
            className += "grade-table " + GRADING_CELL_BACKGROUND[tag][level];
        }

        if (btnSavedClasses[tag] === level) {
            className += " btnClass saved " + GRADING_CELL_BACKGROUND[tag][level];
        }

        return className;
    }

    return (
        <TableRow className="grade-table">
            <TableCell className="grade-table" style={{ backgroundColor: GRADING_CELL_LEVEL_COLOR[level], textAlign: "center" }}>
                {level}
            </TableCell>
            <TableCell style={{cursor: isEditable ? "pointer" : "default"}} className={setCellClassName(GRADING_COLUMNS_TAGS.CRTag)} onClick={() => { setRating(GRADING_COLUMNS_TAGS.CRTag, level) }}>
                <button disabled={!isEditable} className="grade-button">{GRADING_CELL_INFOTEXT[GRADING_COLUMNS_TAGS.CRTag][level]}</button>
            </TableCell>
            <TableCell style={{cursor: isEditable ? "pointer" : "default"}} className={setCellClassName(GRADING_COLUMNS_TAGS.TeamRTag)} onClick={() => { setRating(GRADING_COLUMNS_TAGS.TeamRTag, level) }}>
                <button disabled={!isEditable} className="grade-button">{GRADING_CELL_INFOTEXT[GRADING_COLUMNS_TAGS.TeamRTag][level]}</button>
            </TableCell>
            <TableCell style={{cursor: isEditable ? "pointer" : "default"}} className={setCellClassName(GRADING_COLUMNS_TAGS.BRTag)} onClick={() => { setRating(GRADING_COLUMNS_TAGS.BRTag, level) }}>
                <button disabled={!isEditable} className="grade-button">{GRADING_CELL_INFOTEXT[GRADING_COLUMNS_TAGS.BRTag][level]}</button>
            </TableCell>
            <TableCell style={{cursor: isEditable ? "pointer" : "default"}} className={setCellClassName(GRADING_COLUMNS_TAGS.SRTag)} onClick={() => { setRating(GRADING_COLUMNS_TAGS.SRTag, level) }}>
                <button disabled={!isEditable} className="grade-button">{GRADING_CELL_INFOTEXT[GRADING_COLUMNS_TAGS.SRTag][level]}</button>
            </TableCell>
            <TableCell style={{cursor: isEditable ? "pointer" : "default"}} className={setCellClassName(GRADING_COLUMNS_TAGS.FRTag)} onClick={() => { setRating(GRADING_COLUMNS_TAGS.FRTag, level) }}>
                <button disabled={!isEditable} className="grade-button">{GRADING_CELL_INFOTEXT[GRADING_COLUMNS_TAGS.FRTag][level]}</button>
            </TableCell>
            <TableCell style={{cursor: isEditable ? "pointer" : "default"}} className={setCellClassName(GRADING_COLUMNS_TAGS.TechRTag)} onClick={() => { setRating(GRADING_COLUMNS_TAGS.TechRTag, level) }}>
                <button disabled={!isEditable} className="grade-button">{GRADING_CELL_INFOTEXT[GRADING_COLUMNS_TAGS.TechRTag][level]}</button>
            </TableCell>
            <TableCell style={{cursor: isEditable ? "pointer" : "default"}} className={setCellClassName(GRADING_COLUMNS_TAGS.IPRR)} onClick={() => { setRating(GRADING_COLUMNS_TAGS.IPRR, level) }}>
                <button disabled={!isEditable} className="grade-button">{GRADING_CELL_INFOTEXT[GRADING_COLUMNS_TAGS.IPRR][level]}</button>
            </TableCell>
        </TableRow>
    );
}