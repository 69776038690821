import { useState, useEffect } from "react";
import { db } from '../../../firebase';
import { useGetAllQuestions } from "../../../hooks/questionData/useGetAllQuestions";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import { useGetUserNames } from "../../../hooks/userData/useGetUserNames";
import { collection, doc, getDoc, getDocs, orderBy, query } from "firebase/firestore";
import { Box, Divider, Grid, Paper, Stack } from "@mui/material";
import { formatDate } from "date-fns";

import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined';
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import DOMPurify from 'dompurify'

const ExitSummaryBD = ({ projectID }) => {
    const questions = useGetAllQuestions();
    const [userNames] = useGetUserNames();
    const [internalComments, setInternalComments] = useState([]);
    const [externalComments, setExternalComments] = useState([]);

    const [update, setUpdate] = useState(false);
    const [allQuestions] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    function getNames(userId) {
        let username;
        userNames.forEach((userName) => {
            if (userId === userName.id) {
                username = userName.value;
                //console.log('User:' + username + '!')
            }
            //return username
        })
        return username
    }

    useEffect(() => {
        internalComments.forEach((comment) => {
            comment.createdByName = getNames(comment.createdBy);
        });

        externalComments.forEach((comment) => {
            comment.createdByName = getNames(comment.createdBy);
        })

        setUpdate(!update)
    }, [userNames, internalComments, externalComments]);

    useEffect(() => {
        setIsLoading(true);

        const func = async () => {
            const localInternal = [];
            const localExternal = [];
            internalComments.length = 0;
            externalComments.length = 0;

            await getDocs(query(collection(db, "Projects/" + projectID + "/ExternalNotes"), orderBy('created'))).then(async (documents) => {
                for (let index = 0; index < documents.docs.length; index++) {
                    const element = documents.docs[index];
                    const comment = {
                        id: element.id,
                        message: element.data().message,
                        location: element.data().location,
                        step: element.data().step,
                        created: element.data().created,
                        createdBy: element.data().createdBy,
                        createdByName: getNames(element.data().createdBy),
                    };

                    if (element.data().assignedID != null && element.data().step != null) {
                        await getDoc(doc(db, "Projects/" + projectID + "/Steps/" + element.data().step + "/AssignedQuestions/" + element.data().assignedID))
                            .then((data) => {
                                if (data.data() != null) {
                                    const question = questions.find(({ id }) => id === data.data().questionID);
                                    if (question != null) {
                                        comment["question"] = question;
                                    }
                                }
                            });
                    }

                    localExternal.push(comment)
                }

                setExternalComments(localExternal);
            });

            await getDocs(query(collection(db, "Projects/" + projectID + "/InternalNotes"), orderBy('created'))).then(async (documents) => {
                for (let index = 0; index < documents.docs.length; index++) {
                    const element = documents.docs[index];
                    const comment = {
                        id: element.id,
                        message: element.data().message,
                        location: element.data().location,
                        step: element.data().step,
                        created: element.data().created,
                        createdBy: element.data().createdBy,
                        createdByName: getNames(element.data().createdBy),
                    };

                    if (element.data().assignedID != null && element.data().step != null) {
                        await getDoc(doc(db, "Projects/" + projectID + "/Steps/" + element.data().step + "/AssignedQuestions/" + element.data().assignedID))
                            .then((data) => {
                                if (data.data() != null) {
                                    const question = questions.find(({ id }) => id === data.data().questionID);
                                    if (question != null) {
                                        comment["question"] = question;
                                    }
                                }
                            });
                    }

                    localInternal.push(comment)
                }

                setInternalComments(localInternal);
            });

            setIsLoading(false);
        }

        func();

        return () => {
            setInternalComments([]);
            setExternalComments([]);
        }

    }, [projectID, questions]);

    const setMessage = (message) => {
        if (message != null) {
            return DOMPurify.sanitize(message.replace('<br>', ""));
        }
        else {
            return "";
        }
    }

    function ascendingComparator(a, b) {
        if (b["order"] > a["order"]) {
            return -1;
        }
        if (b["order"] < a["order"]) {
            return 1;
        }
        return 0;
    }

    function showCommentCard(comment, isLast) {
        let date;
        if (comment.created != null) {
            date = new Date(Number(comment.created.seconds) * 1000)
        }
        else {
            date = '';
        }

        return (
            <Box key={comment.id} sx={{ width: '100%', paddingLeft: '10px', paddingTop: '5px' }}>
                <Stack>
                    <Stack direction={'row'} alignItems='center' justifyContent="space-between">
                        <Stack>
                            <Stack direction={'row'}>
                                <span className="comment_username">
                                    {comment?.createdByName}
                                </span>
                                <span>
                                    {comment?.created != null ? (formatDate(new Date(comment?.created?.seconds * 1000), 'yyyy-MM-dd HH:mm')) : ("Not Set")}
                                </span>

                                <span style={{ color: 'grey', marginLeft: '10px' }}>
                                    {comment?.location}
                                </span>

                                {comment?.assignedID !== "undefined" && comment?.assignedID != null ? (
                                    <span>
                                        <QuizOutlinedIcon
                                            style={{
                                                fontSize: "17px",
                                                marginLeft: "5px",
                                                color: 'lightblue',
                                                //float: "middle",
                                            }}
                                        />
                                    </span>
                                ) : ("")}
                            </Stack>
                            <Stack direction={'row'}>
                                {comment?.message != null && comment?.message !== "" ? (
                                    <ReactQuill
                                        readOnly
                                        theme="bubble"
                                        value={setMessage(comment.message)}
                                    />
                                ) : ("-")}
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
                {isLast !== true ? (
                    <Divider className='blue' sx={{ marginTop: '3px', marginBottom: '6px' }} />
                ) : ("")}

            </Box>
        );
    }

    return (
        <div>
            {isLoading ? (
                <div style={{ display: 'flex', justifyContent: 'center' }}><span><CircularProgress /></span></div>
            ) : (
                <>
                    <Box sx={{ display: 'flex' }}>
                        <Grid container justifyContent={'center'} gap={1}>
                            <Grid item>
                                <Box sx={{ border: '1px solid #4eacc1', borderRadius: '4px', width: '650px' }}>
                                    <Box sx={{ fontSize: '1.2rem', fontWeight: 700, padding: '5px', paddingLeft: '15px' }}>
                                        Internal Comments:
                                    </Box>
                                    <Divider className='blue' />
                                    {internalComments.map((comment, index) => {
                                        return showCommentCard(comment, index === internalComments.length - 1);
                                    })}
                                </Box>

                            </Grid>

                            <Grid item>
                                <Box sx={{ border: '1px solid #4eacc1', borderRadius: '4px', width: '650px' }}>
                                    <Box sx={{ fontSize: '1.2rem', fontWeight: 700, padding: '5px', paddingLeft: '15px' }}>
                                        External Comments:
                                    </Box>
                                    <Divider className='blue' />
                                    {externalComments.map((comment, index) => {
                                        return showCommentCard(comment, index === externalComments.length - 1);
                                    })}
                                </Box>

                            </Grid>
                        </Grid>
                    </Box>

                    <hr />
                </>
            )}
        </div >
    )
};

export default ExitSummaryBD;