import { useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TableIbo from './IBOTable/Table'
import { useLocation } from 'react-router-dom'
import ProfilePage from './ProfilePage';
import Statistics from '../Statistics/Statistics';
import TabMenuContentTool from '../ContentCreation/TabMenuContentTool';
import DatePickerTimeslot from '../TimePickers/DatePickerTimeslot';
//import DragList from './DragList';


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 1 }}>
                    <Typography component={'div'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function BDTabs() {
    const location = useLocation()
    const selectedTab = location.hash;

    const [value, setValue] = useState(getHashValueInit(selectedTab));

    getHashValue(selectedTab);

    //Reading the initial value from the hash-value
    function getHashValueInit(hashVal) {
        switch (hashVal) {
            case '#IBO':
                return 0;
            case '#Time':
                return 1;
            case '#Profile':
                return 2;
            case '#Statistics':
                return 3;
            case '#ContentTool':
                return 4;
            case '#ContentTool-create':
                return 4;
            case '#ContentTool-manage':
                return 4;
            default:
                return 0;
        }
    }


    function getHashValue(hashVal) {
        switch (hashVal) {
            case '#IBO':
                if (value !== 0) {
                    setValue(0);
                }
                break;
            case '#Time':
                if (value !== 1) {
                    setValue(1);
                }
                break;
            case '#Profile':
                if (value !== 2) {
                    setValue(2);
                }
                break;
            case '#Statistics':
                if (value !== 3) {
                    setValue(3);
                }
                break;
            case '#ContentTool':
                if (value !== 4) {
                    setValue(4);
                }
                break;
            case '#ContentTool-create':
                if (value !== 4) {
                    setValue(4);
                }
                break;
            case '#ContentTool-manage':
                if (value !== 4) {
                    setValue(4);
                }
                break;
            default:
                if (value !== 0) {
                    setValue(0);
                }
                break;
        }
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    console.log("TAB: ", value)

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} indicatorColor="primary" textColor="inherit" onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="IBO Table" {...a11yProps(0)} href={window.location.pathname + "#IBO"} />
                    <Tab label="Timeslot Tool" {...a11yProps(1)} href={window.location.pathname + "#Time"} />
                    <Tab label="My profile" {...a11yProps(2)} href={window.location.pathname + "#Profile"} />
                    <Tab label="Statistics" {...a11yProps(3)} href={window.location.pathname + "#Statistics"} />
                    <Tab label="Content Tool" {...a11yProps(4)} href={window.location.pathname + "#ContentTool"} />
                </Tabs>
            </Box>
            <TabPanel value={Number(value)} index={0}>
                <TableIbo />
            </TabPanel>
            <TabPanel value={Number(value)} index={1}>
                <DatePickerTimeslot />
            </TabPanel>
            <TabPanel value={Number(value)} index={2}>
                <ProfilePage />
            </TabPanel>
            <TabPanel value={Number(value)} index={3}>
                <Statistics />
            </TabPanel>
            <TabPanel value={Number(value)} index={4}>
                <TabMenuContentTool />
            </TabPanel>
        </Box>
    );
}
