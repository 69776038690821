import { Box, Button, Checkbox, DialogContent, Divider, FormControlLabel, IconButton, MenuItem, Select, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { BootstrapDialog, BootstrapDialogTitle } from "../../../../helper/bootstrapDialog";
import PendingIcon from '@mui/icons-material/Pending';
import CloseIcon from '@mui/icons-material/Close';
import { TrackLabels } from "../../../../constants/labels/labels";
import { approveAnswersIncubator, confirmTrackChangeAndAssignBD } from "../../../../firebase/cloudFunctions";

import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { useAuth } from "../../../../contexts/AuthContext";
import { useGetGradingTrackConfigurations } from "../../../../hooks/grading/useGetGradingTrackConfigurations";
import { useGetLatestGrading } from "../../../../hooks/grading/useGetLatestGrading";
import { IncubatorActivitionReviewModal } from "../../../IncubatorReview/IncubatorActivitionReviewModal";

/**
 * 
 * @param {object} param0 
 * @param {import("../../../../types/Project").IBOProject} param0.projData
 * @param {Array} param0.bdowners
 * @returns 
 */
export const PendingProgressDialog = ({ projData, bdowners }) => {
    const { currentUser } = useAuth();
    const oldGrading = useGetLatestGrading(projData.id)
    const { loading: loadingConfig, trackConfigurations } = useGetGradingTrackConfigurations(projData.id);

    const preSelectedBD = () => {
        if (projData?.BDOwner != null && Array.isArray(projData?.BDOwner) === true) {
            if (projData?.BDOwner[0] != null && projData?.BDOwner[0] !== "") {
                return String(projData?.BDOwner[0]);
            }
        }

        return '';
    }


    const [loading, setLoading] = useState(false);
    const [submittingData, setSubmittingData] = useState(false);
    const [gradingComplete, setGradingComplete] = useState(false);
    const [questionsAnswered, setQuestionsAnswered] = useState(false);
    const [questionsApproved, setQuestionsApproved] = useState(false);
    const [selectedBD, setSelectedBD] = useState(preSelectedBD());
    const [useOldGrading, setUseOldGrading] = useState(false);

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [openReview, setOpenReview] = useState(false);

    const handleOpenReview = () => setOpenReview(true);
    const handleCloseReview = () => setOpenReview(false);

    useEffect(() => {
        if (loadingConfig === false) {
            console.log("Config: ", trackConfigurations)
            let track = "START";

            if (projData.activeTrack != null && projData.activeTrack !== "") {
                track = projData.activeTrack.toLocaleUpperCase();
            }

            if (trackConfigurations != null && trackConfigurations[track] != null && trackConfigurations[track]['startValues'] != null) {
                setGradingComplete(true);
                setUseOldGrading(false);
            }
            else if (oldGrading != null && Object.keys(oldGrading).length >= 5) {
                setUseOldGrading(true);
                setGradingComplete(true);
            }
        }
    }, [loadingConfig, oldGrading]);

    const hasFilledInData = () => {
        if (projData?.needsCompletion === false) {
            return true;
        }
        else if (projData.activeTrack === TrackLabels.incubator.key && projData?.submittedData != null) {
            return true;
        }
        else {
            return false;
        }
    }

    const handleAssignBD = () => {
        setLoading(true);
        confirmTrackChangeAndAssignBD({
            projectID: projData.id,
            userID: selectedBD,
            useOldGrading: useOldGrading,
        }).then(() => {
            handleClose();
        }).catch((error) => {
            console.error('Error: ', error);
        }).finally(() => {
            setLoading(false);
        });
    }

    const handleApproveAnswers = () => {
        setQuestionsApproved(true);
        setSubmittingData(true);
        approveAnswersIncubator({
            projectID: projData.id,
        }).then(() => {
            console.log("Approved answers");
            handleCloseReview();
        }).catch((error) => {
            console.error('Error: ', error);
        }).finally(() => {
            setSubmittingData(false);
        })
    }

    const disableActivateBtn = () => {
        if (loading === true) {
            return true;
        }
        else {
            if (hasFilledInData() === false || !gradingComplete || !gradingComplete || !gradingComplete || selectedBD == null || selectedBD === "") {
                return true;
            }
            else if (projData.activeTrack === TrackLabels.incubator.key){
                if (projData?.approvedData !== true){
                    return true;
                }
            }
        }

        return false;
    }

    return (
        <>
            <IconButton sx={{ height: '22px', width: '22px', marginRight: '-2px' }} size='small' onClick={handleClickOpen}>
                <PendingIcon htmlColor='#efb236' />
            </IconButton>

            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <BootstrapDialogTitle onClose={handleClose}>
                    <h6 style={{ padding: '10px 10px 0px 10px' }}><strong>{TrackLabels[projData.activeTrack].label} activation checklist</strong></h6>
                </BootstrapDialogTitle>
                <Divider sx={{ borderColor: '#4eacc1' }} />
                <DialogContent>
                    <Stack spacing={1}>
                        <Stack height={'30px'} direction={'row'} spacing={1} alignItems={'center'} justifyContent={'flex-start'}>
                            {hasFilledInData() ? (
                                <CheckRoundedIcon htmlColor="#44a829" />
                            ) : (
                                <Box sx={{ width: '25px' }}>

                                </Box>
                            )}

                            <Box fontSize={'1rem'}>
                                {`Filled in required data about company and business idea`}
                            </Box>
                        </Stack>

                        {projData.activeTrack === TrackLabels.incubator.key && hasFilledInData() ? (
                            <Stack height={'30px'} direction={'row'} spacing={1} alignItems={'center'} justifyContent={'flex-start'}>
                                {projData?.approvedData === true ? (
                                    <CheckRoundedIcon htmlColor="#44a829" />
                                ) : (
                                    <Box sx={{ width: '25px' }}>

                                    </Box>
                                )}

                                <Box fontSize={'1rem'}>
                                    {`Approved the required data |`}
                                    <Button onClick={handleOpenReview} sx={{ textTransform: 'none', height: '18px' }}>
                                        Open answers
                                    </Button>
                                </Box>
                            </Stack>
                        ) : ("")}

                        <Stack height={'30px'} direction={'row'} spacing={1} alignItems={'center'} justifyContent={'flex-start'}>
                            {gradingComplete ? (
                                <CheckRoundedIcon htmlColor="#44a829" />
                            ) : (
                                <Box sx={{ width: '25px' }}>

                                </Box>
                            )}

                            <Box fontSize={'1rem'}>
                                {`Readiness level matrix - ${TrackLabels[projData.activeTrack].label} start values`}
                            </Box>
                        </Stack>

                        <Stack height={'30px'} direction={'row'} spacing={1} alignItems={'center'} justifyContent={'flex-start'}>
                            <Box sx={{ width: '25px' }}>
                                {selectedBD != null && selectedBD !== "" ? (
                                    <CheckRoundedIcon htmlColor="#44a829" />
                                ) : ("")}
                            </Box>

                            <Box fontSize={'1rem'} >
                                {`${TrackLabels[projData.activeTrack].label} Business Designer`}
                            </Box>
                            <Select
                                id="select-bd"
                                value={selectedBD}
                                size="small"
                                sx={{ minWidth: '250px' }}
                                variant="outlined"
                                onChange={(event) => setSelectedBD(event.target.value)}>

                                <MenuItem value={''}></MenuItem>
                                {bdowners.map((owner) => {
                                    return (
                                        <MenuItem
                                            key={owner.id}
                                            value={owner.id}
                                        >
                                            {owner.value}
                                        </MenuItem>
                                    );
                                })}

                            </Select>
                        </Stack>

                        <Stack sx={{ paddingTop: '20px' }} alignItems={'center'} justifyContent={'space-between'}>
                            <Button onClick={handleAssignBD} disabled={disableActivateBtn()} sx={{ width: '200px' }} variant="contained">
                                Activate
                            </Button>
                        </Stack>
                    </Stack>
                </DialogContent>
            </BootstrapDialog>

            <IncubatorActivitionReviewModal projData={projData} open={openReview} handleClose={handleCloseReview} handleApprove={handleApproveAnswers} submittingData={submittingData} />
        </>
    );
}