import { Autocomplete, Box, Divider, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, Radio, RadioGroup, TextField } from "@mui/material"
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { CompanyChoices } from "../../constants/labels/labels"
import { useEffect, useState } from "react"
import { COUNTRY_LIST, COUNTY_LIST, REGION_LIST } from "../../constants/lang/general/regions"
import { OwnershipCard } from "../ExtendedCompanyQuestions/CompanyOwnership/OwnershipCard"
import dayjs from "dayjs"


export const QuarterReportInfoCompInfo = ({ handleChange, values, confirmInfo, setSaveTrigger, setInvalidData }) => {
    const setInitYearFounded = () => {
        if (values.founded != null && values.founded !== "") {
            let localYear = dayjs(new Date(Date.parse(values.founded)));
            return localYear;
        }
        else {
            return null;
        }
    }

    const [projectID, setProjectID] = useState("");
    const [creatingProj, setCreatingProj] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [update, setUpdate] = useState(false);

    const [autoSave, setAutoSave] = useState('');

    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [errorInfo, setErrorInfo] = useState('');


    const [year, setYear] = useState(setInitYearFounded());
    const [isExistingCompInvalid, setExistingCompInvalid] = useState(false);
    const [isCompNameInvalid, setCompNameInvalid] = useState(false);
    const [isCompFormInvalid, setCompFormInvalid] = useState(false);
    const [isOrgNumInvalid, setOrgNumInvalid] = useState(false);
    const [isCompCityInvalid, setCompCityInvalid] = useState(false);
    const [isRegionInvalid, setRegionInvalid] = useState(false);
    const [isCountyInvalid, setCountyInvalid] = useState(false);
    const [isCountryInvalid, setCountryInvalid] = useState(false);
    const [isCompRoleInvalid, setCompRoleInvalid] = useState(false);
    const [isRevenueInvalid, setRevenueInvalid] = useState(false);
    const [isNumEmployeesInvalid, setNumEmployeesInvalid] = useState(false);
    const [isYearFoundedInvalid, setYearFoundedInvalid] = useState(false);
    const [isOwnershipInvalid, setOwnershipInvalid] = useState(false);
    const [isGeneralInvalid, setGeneralInvalid] = useState(false);

    useEffect(() => {
        if (confirmInfo === true) {
            console.log("Check fields!")
            checkIfFilledIn();
        }
    }, [confirmInfo]);

    useEffect(() => {
        setYear(setInitYearFounded());
    }, [values]);

    const handleChangeLocal = (e) => {
        handleChange(e);
        setSaveTrigger(e.target.value);
        setUpdate(!update);
    };

    const handleChangeRegion = (value) => {
        //console.log(value);
        handleChange({ target: { name: "region", value: value } });
        setSaveTrigger(value);
        setUpdate(!update);
    };

    const handleChangeCounty = (value) => {
        //console.log(value);
        handleChange({ target: { name: "county", value: value } });
        setSaveTrigger(value);
        setUpdate(!update);
    };

    const handleChangeCountry = (value) => {
        //console.log(value);
        handleChange({ target: { name: "country", value: value } });
        setSaveTrigger(value);
        setUpdate(!update);
    };

    const handleChangeOwneship = (value) => {
        handleChange({ target: { name: "compOwnership", value: value } });
        setSaveTrigger(value);
        setUpdate(!update);
    }

    const onChangeYear = (value) => {
        setYear(value);
        handleChange({ target: { name: "founded", value: value['$d'].getFullYear() } });
        setYearFoundedInvalid(false);
        setSaveTrigger(value['$d']);
    }

    const checkIfFilledIn = () => {
        let isInvalid = false;
        if (values.existingComp == null || values.existingComp === "") {
            setExistingCompInvalid(true);
            isInvalid = true;
        }
        else {
            if (values.existingComp === CompanyChoices.yes_new || values.existingComp === CompanyChoices.yes_established) {
                if (values.compName == null || values.compName === "") {
                    setCompNameInvalid(true);
                    isInvalid = true;
                }

                if (values.orgNum == null || values.orgNum === "") {
                    setOrgNumInvalid(true);
                    isInvalid = true;
                }

                if (values.compCity != null && values.compCity !== "") {
                    if (values.region == null || values.region === "" || values.region.label === '') {
                        setRegionInvalid(true);
                        isInvalid = true;
                    }

                    if (values.county == null || values.county === "" || values.county.label === '') {
                        if (values.region?.label !== "Skåne") {
                            setCountyInvalid(true);
                            isInvalid = true;
                        }
                        else if (values.region?.label !== "Outside Sweden") {
                            setCountryInvalid(true);
                            isInvalid = true;
                        }
                    }
                }
                else {
                    setCompCityInvalid(true);
                    isInvalid = true;
                }
            }

            if (values.existingComp === CompanyChoices.yes_established) {
                if (values.founded == null || values.founded === "") {
                    setYearFoundedInvalid(true);
                    isInvalid = true;
                }

                if (values.compRole == null || values.compRole === "") {
                    setCompRoleInvalid(true);
                    isInvalid = true;
                }

                if (values.compOwnership == null || values.compOwnership === "") {
                    setOwnershipInvalid(true);
                    isInvalid = true;
                }

                if (values.revenue == null || values.revenue === "") {
                    setRevenueInvalid(true);
                    isInvalid = true;
                }

                if (values.numEmployees == null || values.numEmployees === "") {
                    setNumEmployeesInvalid(true);
                    isInvalid = true;
                }
            }
        }

        console.log("Valid Data?", isInvalid)
        setInvalidData(isInvalid);
    }

    const setErrorMessage = (type) => {
        switch (type) {
            case 'existingComp':
                if (isExistingCompInvalid) {
                    return "Please choose one option";
                }
                else {
                    return "";
                }
            case 'compForm':
                if (isCompFormInvalid) {
                    return "Please choose one option";
                }
                else {
                    return "";
                }
            case 'compName':
                if (isCompNameInvalid) {
                    return "Answer is required";
                }
                else {
                    return "";
                }
            case 'orgNum':
                if (isOrgNumInvalid) {
                    return "Organization number is required";
                }
                else {
                    return "";
                }
            case 'compCity':
                if (isCompCityInvalid) {
                    return "Answer is required";
                }
                else {
                    return "";
                }
            case 'region':
                if (isRegionInvalid) {
                    return "Answer is required";
                }
                else {
                    return "";
                }
            case 'county':
                if (isCountyInvalid) {
                    return "Answer is required";
                }
                else {
                    return "";
                }
            case 'compRole':
                if (isCompRoleInvalid) {
                    return "Answer is required";
                }
                else {
                    return "";
                }
            case 'revenue':
                if (isRevenueInvalid) {
                    return "Please choose one option"
                }
                else {
                    return ""
                }
            case 'general':
                if (isGeneralInvalid === true) {
                    return "Please fill the required fields."
                }
                else {
                    return ""
                }
            default:
                //console.log("Other: ", type)
                break;
        }
    }


    const validateInput = (type) => {
        switch (type) {
            case 'existingComp':
                if (values.existingComp != null && values.existingComp !== "") {
                    setExistingCompInvalid(false);
                }
                else {
                    setExistingCompInvalid(true);
                }
                break;
            case "compName":
                if (values.compName != null && values.compName !== "") {
                    setCompNameInvalid(false);
                }
                else {
                    setCompNameInvalid(true);
                }
                break;
            case "orgNum":
                if (values.orgNum != null && values.orgNum !== "") {
                    setOrgNumInvalid(false);
                }
                else {
                    setOrgNumInvalid(true);
                }
                break;

            case "compCity":
                if (values.compCity != null && values.compCity !== "") {
                    setCompCityInvalid(false);
                    if (values.region != null && values.region !== "" && values.region.label !== '') {
                        setRegionInvalid(false);
                    }
                    else {
                        setRegionInvalid(true);
                    }
                    if (values.county != null && values.county !== "" && values.county.label !== '') {
                        setRegionInvalid(false);
                    }
                    else {
                        if (values.region?.label === "Skåne") {
                            setCountyInvalid(false);
                        }
                        else {
                            setCountyInvalid(true);
                        }
                    }
                }
                else {
                    setCompCityInvalid(true);
                }
                break;
            case "compRole":
                if (values.compRole != null && values.compRole !== "") {
                    setCompRoleInvalid(false);
                }
                else {
                    setCompRoleInvalid(true);
                }
                break;
            default:
                //console.log("Other: ", type)
                break;
        }
    }

    return (
        <Box>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Grid container spacing={3} justifyContent="center" padding={3}>
                    <Grid item xs={12}>
                        <h6 style={{ fontWeight: 'bold', marginBottom: '10px', }}> Company:</h6>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <FormControl required>
                            <FormLabel id="demo-controlled-radio-buttons-group" style={{ marginTop: '10px' }}>Are you developing this idea witin an existing company?</FormLabel>
                            <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="existingComp"
                                value={values.existingComp || ""}
                                onChange={handleChangeLocal}
                            >
                                <FormControlLabel value={CompanyChoices['no']} control={<Radio />} label={CompanyChoices['no']} />
                                <FormControlLabel value={CompanyChoices['yes_new']} control={<Radio />} label={CompanyChoices['yes_new']} />
                                <FormControlLabel value={CompanyChoices['yes_established']} control={<Radio />} label={CompanyChoices['yes_established']} />

                            </RadioGroup>
                            <FormHelperText error={isExistingCompInvalid}>{setErrorMessage('existingComp')}</FormHelperText>
                        </FormControl>
                    </Grid>

                    {(values.existingComp === CompanyChoices['yes_new'] || values.existingComp === CompanyChoices['yes_established']) &&
                        <>
                            <Grid item xs={12} sm={12} md={6}>
                                <FormControl required>
                                    <FormLabel id="demo-controlled-radio-buttons-group" error={isCompFormInvalid}>Company form</FormLabel>
                                    <RadioGroup
                                        aria-labelledby="demo-controlled-radio-buttons-group"
                                        name="compForm"
                                        value={values.compForm || ""}
                                        onChange={handleChangeLocal}
                                    >
                                        <FormControlLabel value="Enskild firma (or equivalent)" control={<Radio />} label="Enskild firma (or equivalent)" />
                                        <FormControlLabel value="Handelsbolag/Kommanditbolag (or equivalent)" control={<Radio />} label="Handelsbolag / Kommanditbolag (or equivalent)" />
                                        <FormControlLabel value="Aktiebolag (or equivalent)" control={<Radio />} label="Aktiebolag (or equivalent)" />

                                    </RadioGroup>
                                    <FormHelperText error={isCompFormInvalid}>{setErrorMessage('compForm')}</FormHelperText>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={12} md={6}>
                                <TextField
                                    required
                                    fullWidth
                                    label="Company Name"
                                    name="compName"
                                    placeholder="Enter your company name"

                                    value={values.compName || ""}
                                    onChange={handleChangeLocal}
                                    onBlur={() => { validateInput('compName') }}
                                    error={isCompNameInvalid}
                                    helperText={setErrorMessage('compName')}
                                    margin="normal"
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6}>
                                <TextField
                                    required
                                    fullWidth
                                    label="Organization number"
                                    name="orgNum"
                                    placeholder="Enter number"
                                    value={values.orgNum || ""}
                                    onChange={handleChangeLocal}
                                    onBlur={() => { validateInput('orgNum') }}
                                    error={isOrgNumInvalid}
                                    helperText={setErrorMessage('orgNum')}
                                    margin="normal"
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6}>
                                <Autocomplete
                                    disablePortal
                                    autoComplete
                                    value={values.region}
                                    options={REGION_LIST}
                                    onChange={(event, newValue) => {
                                        handleChangeRegion(newValue);
                                    }}
                                    fullWidth
                                    renderOption={(props, option) => {
                                        if (option?.label === 'Outside Sweden') {
                                            return (
                                                <>
                                                    <Divider sx={{ borderBottomWidth: 2, borderColor: '#4eacc1' }} />
                                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                        <i>{option.label}</i>
                                                    </Box>
                                                </>
                                            );
                                        }
                                        else {
                                            return (
                                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                    {option.label}
                                                </Box>
                                            )
                                        }
                                    }}
                                    renderInput={(params) =>
                                        <TextField {...params}
                                            label="Region (headquarter if multiple)"
                                            error={isRegionInvalid}
                                            helperText={setErrorMessage('region')}
                                            margin="normal" />
                                    }
                                />

                                {values.region?.label === "Skåne" ? (
                                    <Autocomplete
                                        disablePortal
                                        autoComplete
                                        value={values.county}
                                        options={COUNTY_LIST}
                                        onChange={(event, newValue) => {
                                            handleChangeCounty(newValue);
                                        }}
                                        fullWidth
                                        renderInput={(params) => <TextField {...params} label="County" error={isCountyInvalid} helperText={setErrorMessage('county')} margin="normal" />}
                                    />) : ("")}

                                {values.region?.label === "Outside Sweden" ? (
                                    <Autocomplete
                                        disablePortal
                                        autoComplete
                                        value={values.country}
                                        options={COUNTRY_LIST}
                                        onChange={(event, newValue) => {
                                            handleChangeCountry(newValue);
                                        }}
                                        fullWidth
                                        renderInput={(params) => <TextField {...params} label="Country" error={isCountryInvalid} helperText={setErrorMessage('country')} margin="normal" />}
                                    />
                                ) : ("")}
                            </Grid>
                        </>
                    }

                    {values.existingComp === CompanyChoices['yes_established'] &&
                        <>
                            <Grid item xs={12} sm={12} md={6}>
                                <DesktopDatePicker
                                    views={['year']}
                                    label="Year founded"
                                    format="YYYY"
                                    value={year}
                                    onChange={onChangeYear}
                                    slotProps={{ textField: { helperText: null, margin: 'normal', fullWidth: true, error: isYearFoundedInvalid } }}
                                />

                            </Grid>

                            <Grid item xs={12} sm={12} md={6}>
                                <TextField
                                    fullWidth
                                    required
                                    label="Your role in the company"
                                    name="compRole"
                                    placeholder="Answer"
                                    value={values.compRole || ""}
                                    margin="normal"
                                    onChange={handleChangeLocal}
                                    onBlur={() => { validateInput('compRole') }}
                                    error={isCompRoleInvalid}
                                    helperText={setErrorMessage('compRole')}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6}>
                                <OwnershipCard value={values?.compOwnership} handleChangeOwneship={handleChangeOwneship} isOwnershipInvalid={isOwnershipInvalid} setOwnershipInvalid={setOwnershipInvalid} />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6}>
                                <FormControl required>
                                    <FormLabel id="demo-controlled-radio-buttons-group" error={isRevenueInvalid}>Revenue (latest full fiscal year)</FormLabel>
                                    <RadioGroup
                                        aria-labelledby="demo-controlled-radio-buttons-group"
                                        name="revenue"
                                        value={values.revenue}
                                        onChange={handleChangeLocal}
                                    >
                                        <FormControlLabel value="&lt;500k SEK" control={<Radio />} label="&lt;500k SEK" />
                                        <FormControlLabel value="501k - 2m SEK" control={<Radio />} label="501k - 2m SEK" />
                                        <FormControlLabel value="2-10m SEK" control={<Radio />} label="2-10m SEK" />
                                        <FormControlLabel value="&gt;10m SEK" control={<Radio />} label="&gt;10m SEK" />

                                    </RadioGroup>
                                    <FormHelperText error={isRevenueInvalid}>{setErrorMessage('revenue')}</FormHelperText>
                                </FormControl>
                            </Grid>



                            <Grid item xs={12} sm={12} md={6}>
                                <FormControl required>
                                    <FormLabel id="demo-controlled-radio-buttons-group" error={isNumEmployeesInvalid}>Number of employees</FormLabel>
                                    <RadioGroup
                                        aria-labelledby="demo-controlled-radio-buttons-group"
                                        name="numEmployees"
                                        value={values.numEmployees || ""}
                                        onChange={handleChangeLocal}
                                    >
                                        <FormControlLabel value="0" control={<Radio />} label="0" />
                                        <FormControlLabel value="1-4" control={<Radio />} label="1-4" />
                                        <FormControlLabel value="5-10" control={<Radio />} label="5-10" />
                                        <FormControlLabel value="&gt;10" control={<Radio />} label="&gt;10" />
                                    </RadioGroup>
                                    <FormHelperText error={isNumEmployeesInvalid}>{setErrorMessage('numEmployees')}</FormHelperText>
                                </FormControl>
                            </Grid>
                        </>
                    }
                </Grid>
            </LocalizationProvider>
        </Box>
    )
}