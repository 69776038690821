/**
 * 
 * @param {function():void} handleOpenInfo 
 * @param {function():void} handleOpenVideo 
 * @returns 
 */
export const INTRODUCTION_PARAGRAPHS_START = (handleOpenInfo, handleOpenVideo) => {
    return {
        header:  <h1 className="h1-mobile">Välkommen till plattformen för Krinova START!</h1>,
        paragraph1: <p className="content">Den här plattformen kommer att guida dig genom processen att utveckla och validera din affärsidé, vägledd av din tilldelade Business Designer från Krinova. Plattformen för Krinova START är utformad som en online-baserad lärandeprocess som kommer ta dig igenom de viktigaste aspekterna och verktygen för att utveckla din idé till ett företag, bygga en Affärsmodell och göra dig redo att testa din produkt eller tjänst på marknaden med en prototyp. Vi räknar med att den här processen pågår under 4-6 månader, beroende på hur mycket tid du kan ägna åt din idé.</p>,
        paragraph2: <p className="content">Grafiken överst på sidan representerar din väg genom processen, och fungerar även som navigeringsverktyget för att nå de olika stegen. Cirklarna representerar checkpoints, i form av möten med din Business Designer. Använd tiden under dessa möten effektivt för att utvärdera arbetet som har utförts och för att diskutera utmaningar och strategier för att driva din idé framåt. Länkarna mellan checkpoints representerar de hemuppgifter som ska utföras mellan mötena. Uppgifterna behöver vara slutförda, och godkända av din Business Designer, för att nästa steg i processen ska kunna påbörjas.{/* Mer information om hur du använder den här plattformen och hur den kommer vägleda dig genom arbetet, hittar du <span onClick={handleOpenInfo} className="open-modal-link" ><strong>här</strong></span>. */}</p>,
        paragraph3: <p className="content">I den övre panelen till höger kan du se datum för ditt nästa möte/checkpoint alternativt deadline för pågående hemuppgifter, ändra inställningarna för vilka valfria epostnotifieringar du får, eller lägga till ytterligare deltagare i ditt projekt.</p>,
        paragraph4: <p className="content">Ett av de integrerade verktyg som kommer användas genom processen är den digitala whiteboard-tjänsten Miro. Om du skulle vilja bekanta dig med dess gränssnitt och funktionalitet medan du inväntar dina första hemuppgifter, så kan du titta på några av deras introduktionsvideor <span onClick={handleOpenVideo} className="open-modal-link"><strong>här</strong></span>.</p>,
        paragraph5: <p className="content">När din Business Designer har skapat en länk till din Miro board så kommer du alltid kunna nå den via snabblänken i panelen till höger, vid sidan av att den finns tillgänglig på relevanta platser i dina tilldelade hemuppgifter.</p>,
        paragraph6: <p className="content">En sista sak: även om den här plattformen huvudsakligen är engelskspråkig, inklusive instruktioner för hemuppgifter, så går det utmärkt att använda svenska i dina svar och beskrivningar, samt i din kommunikation med din tilldelade Business Designer.</p>,
        paragraph7: <p className="content">Lycka till!</p>,
    }
}

/**
 * 
 * @param {function():void} handleOpenInfo 
 * @param {function():void} handleOpenVideo 
 * @returns 
 */
export const INTRODUCTION_PARAGRAPHS_INCUBATOR = (handleOpenInfo, handleOpenVideo) => {
    return {
        header:  <h1 className="h1-mobile">Välkommen till plattformen för Krinova INCUBATOR!</h1>,
        paragraph1: <p className="content">Din profil här på plattformen används för att ge din tilldelade Business Designer all nödvändig information gällande dig själv, ditt företag och din affärsidé, såväl som att säkerställa att den hålls uppdaterad och korrekt. För att granska, eller ändra, den information som du för närvarande angivit, gå till din profil genom att klicka på knappen till höger eller Entry-steget i grafiken ovan. Du kommer notifieras via e-post när det är dags för en regelbunden obligatoriska granskning av dina uppgifter.</p>,
        paragraph2: <p className="content">Ett av de integrerade verktyg som kommer användas genom processen för Krinova Business Growth är den digitala whiteboard-tjänsten Miro. Om du skulle vilja bekanta dig med dess gränssnitt och funktionalitet så kan du titta på några av deras introduktionsvideor <span onClick={handleOpenVideo} className="open-modal-link"><strong>här</strong></span>. När din Business Designer har skapat en länk till din Miro board så når du den via snabblänken i panelen till höger. Här hittar du även knappen för att bjuda in ytterligare deltagare till din företagsprofil på plattformen.</p>,
        paragraph3: <p className="content">Lycka till!</p>,
    }
}

/**
 * 
 * @param {function():void} handleOpenInfo 
 * @param {function():void} handleOpenVideo 
 * @returns 
 */
export const INTRODUCTION_PARAGRAPHS_SCALE = (handleOpenInfo, handleOpenVideo) => {
    return {
        header:  <h1 className="h1-mobile">Välkommen till plattformen för Krinova SCALE!</h1>,
        paragraph1: <p className="content">Din profil här på plattformen används för att ge din tilldelade Business Designer all nödvändig information gällande dig själv, ditt företag och din affärsidé, såväl som att säkerställa att den hålls uppdaterad och korrekt. För att granska, eller ändra, den information som du för närvarande angivit, gå till din profil genom att klicka på knappen till höger eller Entry-steget i grafiken ovan. Du kommer notifieras via e-post när det är dags för en regelbunden obligatoriska granskning av dina uppgifter.</p>,
        paragraph2: <p className="content">Ett av de integrerade verktyg som kommer användas genom processen för Krinova Business Growth är den digitala whiteboard-tjänsten Miro. Om du skulle vilja bekanta dig med dess gränssnitt och funktionalitet så kan du titta på några av deras introduktionsvideor <span onClick={handleOpenVideo} className="open-modal-link"><strong>här</strong></span>. När din Business Designer har skapat en länk till din Miro board så når du den via snabblänken i panelen till höger. Här hittar du även knappen för att bjuda in ytterligare deltagare till din företagsprofil på plattformen.</p>,
        paragraph3: <p className="content">Lycka till!</p>,
    }
}

export const SETTINGSTEXT = {
    deadline: "Ditt nästa möte/deadline: ",
    notificationsHeader: "Inställningar för e-postmeddelanden:",
    notificationsLabel: "Välj vilka valfria e-postmeddelanden du vill få.",
    unlocked: "Ny hemuppgift upplåst",
    oneWeek: "Påminnelse 1 vecka innan deadline för hemuppgift",
    oneDay: "Påminnelse 1 dag innan deadline för hemuppgift",
    reviewed: "Hemuppgift godkänd",
    meetingApproved: "Checkpoint (möte) godkänd med kommentarer",
    profileLink: "Profil",
    miroLink: "Miro-länk",
    noMiroLink: "Ingen Miro-link tillagd",
    addPatricipant: "Bjud in deltagare"
}