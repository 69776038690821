import { Autocomplete, Box, Button, Divider, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, LinearProgress, Radio, RadioGroup, TextField } from "@mui/material"
import { useEffect, useMemo, useState } from "react"
import { useGetUserProjectId } from "../../../hooks/userData/useGetUserProjectId"
import { useAuth } from "../../../contexts/AuthContext"
import { useNavigate } from "react-router-dom"
import { getAnalytics, logEvent } from "firebase/analytics"
import { addProjectInfo, createNewProject } from "../../../firebase/cloudFunctions"

import textBubble from '../../../assets/images/tip-bubble.png'
import { COUNTRY_LIST, COUNTY_LIST, REGION_LIST } from "../../../constants/lang/general/regions"
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import dayjs from "dayjs"
import { ErrorAlert } from "../../../helper/errorAlert"
import { companyChoices, CompanyQuestionsEng, CompanyQuestionsSwe, contactingChoices, ProjectInfoQuestionsEng, ProjectInfoQuestionsSwe, ProjectStartQuestionsEng, ProjectStartQuestionsSwe } from "../../../constants/companyQuestions/companyQuestions"

export const CreateNewProject_v2 = ({ handleChange, values }) => {
    const setInitYearFounded = () => {
        if (values.founded != null && values.founded !== "") {
            let localYear = new Date(Date.parse(values.founded));
            return localYear;
        }
        else {
            return null;
        }
    }

    const { projectIDs, loadingProjID } = useGetUserProjectId();
    const { currentUser } = useAuth();
    const navigate = useNavigate();
    const analytics = getAnalytics();

    const [projectID, setProjectID] = useState("");


    const [year, setYear] = useState(setInitYearFounded());
    const [creatingProj, setCreatingProj] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [update, setUpdate] = useState(false);
    const [saveTrigger, setSaveTrigger] = useState('');
    const [autoSave, setAutoSave] = useState('');

    const [queueSubmit, setQueueSubmit] = useState(false);
    const [queueSave, setQueueSave] = useState(false);

    const [isGeneralInvalid, setGeneralInvalid] = useState(false);

    const [contactingKrinova, setContactKrinova] = useState('');
    const [existingCompany, setExistingCompany] = useState('');

    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [errorInfo, setErrorInfo] = useState('');

    const ProjectStartQuestions = useMemo(() => {
        if (currentUser?.userInfo?.prefLang === 'swe') {
            return ProjectStartQuestionsSwe;
        }
        else {
            return ProjectStartQuestionsEng;
        }
    }, [currentUser]);

    const projectInfoQuestions = useMemo(() => {
        if (currentUser?.userInfo?.prefLang === 'swe') {
            return ProjectInfoQuestionsSwe;
        }
        else {
            return ProjectInfoQuestionsEng;
        }
    }, [currentUser]);

    const companyQuestions = useMemo(() => {
        if (currentUser?.userInfo?.prefLang === 'swe') {
            return CompanyQuestionsSwe;
        }
        else {
            return CompanyQuestionsEng;
        }
    }, [currentUser]);

    useEffect(() => {
        if (values?.existingComp != null && values?.existingComp !== "") {
            if (contactingKrinova == null || contactingKrinova === "") {
                if (values?.existingComp === 'no' || values?.existingComp === 'yes_new') {
                    setContactKrinova(contactingChoices.newIdea.key);
                }
                else if (values?.existingComp === 'yes_established') {
                    setContactKrinova(contactingChoices.business.key);
                }
            }

            if (existingCompany == null || existingCompany === "") {
                if (values?.existingComp === 'no') {
                    setExistingCompany(companyChoices.no.key);
                }
                else if (values?.existingComp === 'yes_new') {
                    setExistingCompany(companyChoices.yes.key);
                }
            }
        }
    }, [values, contactingKrinova, existingCompany])

    useEffect(() => {
        if (loadingProjID === false) {
            if (projectIDs == null || projectIDs.length === 0) {
                createProject();
            }
            else {
                setProjectID(projectIDs[0]);
            }

        }
    }, [projectIDs, loadingProjID])

    useEffect(() => {
        if (queueSubmit === true && creatingProj === false) {
            setQueueSubmit(false)

            saveProjectInfo();
        }
    }, [queueSubmit, creatingProj])

    useEffect(() => {
        if (queueSave === true && creatingProj === false) {
            setQueueSave(false)

            updateProjectInfo();
        }
    }, [queueSave, creatingProj])


    const createProject = () => {
        if (creatingProj === false && (projectID == null || projectID === "")) {

            setCreatingProj(true);
            createNewProject({
                uid: currentUser.uid,
            }).then((result) => {
                //console.log("Res: ", result);
                setProjectID(String(result.data))
            }).catch((error) => {
                console.error("Error:", error)
            }).finally(() => {
                //console.log("Completed")
                setCreatingProj(false);
            })
        }
    }

    useEffect(() => {
        if (saveTrigger !== '') {
            const delayDebounceFn = setTimeout(() => {
                saveProgress();
                setSaveTrigger('');
            }, 3000)

            return () => {
                clearTimeout(delayDebounceFn);
            }
        }
    }, [saveTrigger]);

    function saveProgress() {
        if (creatingProj === false) {
            updateProjectInfo();
        }
        else {
            setQueueSave(true);
        }
    }

    const updateProjectInfo = () => {
        setIsLoading(true);
        console.log("update Data! ProjectID: ", projectID)

        const data = {
            projectID: projectID,
            projName: values.projName,
            existingComp: values.existingComp,
            updateData: true,
        }

        if (values.website != null && values.website !== "") {
            data['website'] = values.website
        }

        if (values.existingComp === 'yes_new' || values.existingComp === 'yes_established') {
            data['compForm'] = values.compForm
            data['orgNum'] = values.orgNum
            data['compName'] = values.compName
            data['compCity'] = values.compCity
            data['founded'] = values.founded;
            data['compRole'] = values.compRole;
            data['revenue'] = values.revenue;
            data['numEmployees'] = values.numEmployees;
        }

        console.log("Send: ", data)
        addProjectInfo(data).then(() => {
            setAutoSave(new Date(Date.now()).toLocaleString())
        }).catch((error) => {
            console.error("Error:", error)
        }).finally(() => {
            setIsLoading(false);
        })
    }


    const saveProjectInfo = () => {
        setIsLoading(true);
        const data = {
            projectID: projectID,
            projName: values.projName,
            existingComp: values.existingComp,
        }

        if (values.website != null && values.website !== "") {
            data['website'] = values.website
        }

        if (values.existingComp === 'yes_new' || values.existingComp === 'yes_established') {
            data['compForm'] = values.compForm
            data['orgNum'] = values.orgNum
            data['compName'] = values.compName
            data['compCity'] = values.compCity
            data['founded'] = values.founded;
            data['compRole'] = values.compRole;
            data['revenue'] = values.revenue;
            data['numEmployees'] = values.numEmployees;
        }

        addProjectInfo(data).then(() => {
            logEvent(analytics, 'completed_signup_step', {
                step: 3,
                invited: false,
            })
            navigate('/set-up-account#ideas')
        }).catch((error) => {
            console.error("Error:", error)
            setErrorInfo(error)
            setErrorMsg('Something went wrong! Could not save data.');
            setError(true);
        }).finally(() => {
            setIsLoading(false);
        })
    }

    const displayProjectName = () => {
        if (contactingKrinova === contactingChoices.newIdea.key && existingCompany === companyChoices.no.key) {
            return true;
        }
        else {
            return false;
        }
    }

    const displayCompanyInfo = () => {
        if (contactingKrinova === contactingChoices.business.key || (contactingKrinova === contactingChoices.newIdea.key && existingCompany === companyChoices.yes.key)) {
            return true;
        }
        else {
            return false;
        }
    }

    const handleChangeLocal = (e) => {
        handleChange(e);
        if (e.target.name === 'compName') {
            handleChange({ target: { name: "projName", value: e.target.value } });
        }
        setSaveTrigger(e.target.value);
        setUpdate(!update);
    };

    const handleChangeRegion = (value) => {
        //console.log(value);
        handleChange({ target: { name: "region", value: value } });
        setSaveTrigger(value);
        setUpdate(!update);
    };

    const handleChangeCounty = (value) => {
        //console.log(value);
        handleChange({ target: { name: "county", value: value } });
        setSaveTrigger(value);
        setUpdate(!update);
    };

    const handleChangeCountry = (value) => {
        //console.log(value);
        handleChange({ target: { name: "country", value: value } });
        setSaveTrigger(value);
        setUpdate(!update);
    };

    const onChangeYear = (value) => {
        setYear(value['$d']);
        handleChange({ target: { name: "founded", value: value['$d'].getFullYear() } });
    }

    const getValueInitialQuestions = (key) => {
        switch (key) {
            case ProjectStartQuestions.contactingKrinova.key:
                return contactingKrinova;
            case ProjectStartQuestions.existingCompNewIdea.key:
                return existingCompany;
            default:
                return "";
        }
    }

    const handleChangeInitialQuestions = (event) => {
        let value = event.target.value;
        let name = event.target.name;

        switch (name) {
            case ProjectStartQuestions.contactingKrinova.key:
                setContactKrinova(value);

                if (value === contactingChoices.business.key) {
                    setExistingCompany('')

                    handleChange({ target: { name: "existingComp", value: 'yes_established' } });
                    handleChange({ target: { name: "projName", value: '' } });
                    setSaveTrigger('yes_established');
                    setUpdate(!update);
                }
                break;
            case ProjectStartQuestions.existingCompNewIdea.key:
                setExistingCompany(value);

                if (value === companyChoices.yes.key) {
                    handleChange({ target: { name: "existingComp", value: 'yes_new' } });
                    handleChange({ target: { name: "projName", value: '' } });
                    setSaveTrigger('yes_new');
                    setUpdate(!update);
                }
                else if (value === companyChoices.no.key) {
                    handleChange({ target: { name: "existingComp", value: 'no' } });
                    setSaveTrigger('no');
                    setUpdate(!update);
                }
                break;
            default:
                break;
        }
    }

    function validateAllFields() {
        return false;
    }

    const handleSubmit = e => {
        e.preventDefault()
        //console.log("Submit: ", values)
        if (validateAllFields() === false) {
            setIsLoading(true);
            if (creatingProj === true) {
                setQueueSubmit(true);
            }
            else {
                saveProjectInfo();
            }
        }
    };

    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <form onSubmit={handleSubmit} id="project-form">
                    <Grid container spacing={3} justifyContent="center" padding={3}>
                        {Object.keys(ProjectStartQuestions).map((key) => {
                            if ((key === ProjectStartQuestions.existingCompNewIdea.key && contactingKrinova === contactingChoices.newIdea.key) || key !== ProjectStartQuestions.existingCompNewIdea.key) {
                                if (ProjectStartQuestions[key]?.type === 'radioGroup') {
                                    return (
                                        <Grid key={key} item xs={12} sm={12} md={6}>
                                            <FormControl required={ProjectStartQuestions[key]?.required}>
                                                <FormLabel id={key}>{ProjectStartQuestions[key]?.text}</FormLabel>
                                                <RadioGroup
                                                    sx={{ width: 'fit-content' }}
                                                    aria-labelledby={key}
                                                    name={key}
                                                    value={getValueInitialQuestions(key)}
                                                    onChange={handleChangeInitialQuestions}
                                                >
                                                    {ProjectStartQuestions[key]?.options.map((option) => (
                                                        <FormControlLabel
                                                            key={option?.value}
                                                            value={option?.value}
                                                            control={<Radio />}
                                                            label={option?.label}
                                                        />
                                                    ))}
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                    );
                                }
                            }
                        })}
                        {contactingKrinova !== contactingChoices.newIdea.key ? (
                            <Grid item xs={12} sm={12} md={6}></Grid>
                        ) : (<></>)}

                        {displayProjectName() ? (
                            <>
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>

                                {Object.keys(projectInfoQuestions).map((key) => {
                                    if (projectInfoQuestions[key]?.type === 'textField') {
                                        return (
                                            <Grid key={key} item xs={12} sm={12} md={6}>
                                                <TextField
                                                    fullWidth
                                                    required={projectInfoQuestions[key]?.required === true}
                                                    label={projectInfoQuestions[key]?.text}
                                                    name={key}
                                                    type={'text'}
                                                    onChange={handleChangeLocal}
                                                    value={values[key] || ""}
                                                    placeholder={projectInfoQuestions[key]?.placeHolder != null ? projectInfoQuestions[key]?.placeHolder : ""}
                                                    margin="normal"
                                                />
                                            </Grid>
                                        )
                                    }
                                })}
                            </>
                        ) : ("")}

                        {displayCompanyInfo() ? (
                            <>
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>

                                {Object.keys(companyQuestions).map((key) => {
                                    if (companyQuestions[key]?.type === 'textField') {
                                        return (
                                            <Grid key={key} item xs={12} sm={12} md={6}>
                                                <TextField
                                                    fullWidth
                                                    required={companyQuestions[key]?.required === true}
                                                    label={companyQuestions[key]?.text}
                                                    name={key}
                                                    type={'text'}
                                                    onChange={handleChangeLocal}
                                                    value={values[key] || ""}
                                                    placeholder={companyQuestions[key]?.placeHolder != null ? projectInfoQuestions[key]?.placeHolder : ""}
                                                    margin="normal"
                                                />
                                            </Grid>
                                        )
                                    }
                                    else if (companyQuestions[key]?.type === 'radioGroup') {
                                        return (
                                            <Grid key={key} item xs={12} sm={12} md={6}>
                                                <FormControl required={companyQuestions[key]?.required}>
                                                    <FormLabel id={key}>{companyQuestions[key]?.text}</FormLabel>
                                                    <RadioGroup
                                                        sx={{ width: 'fit-content' }}
                                                        aria-labelledby={key}
                                                        name={key}
                                                        value={values[key] || ""}
                                                        onChange={handleChangeLocal}
                                                    >
                                                        {companyQuestions[key]?.options.map((option) => (
                                                            <FormControlLabel
                                                                key={option?.value}
                                                                value={option?.value}
                                                                control={<Radio />}
                                                                label={option?.label}
                                                            />
                                                        ))}
                                                    </RadioGroup>
                                                </FormControl>
                                            </Grid>
                                        )
                                    }
                                    else if (companyQuestions[key]?.type === 'autoCompleteRegion') {
                                        return (
                                            <Grid key={key} item xs={12} sm={12} md={6}>
                                                <Autocomplete
                                                    disablePortal
                                                    autoComplete
                                                    value={values[key] || null}
                                                    options={companyQuestions[key]?.options}
                                                    onChange={(event, newValue) => {
                                                        handleChangeRegion(newValue);
                                                    }}
                                                    fullWidth
                                                    renderInput={(params) =>
                                                        <TextField {...params}
                                                            label={companyQuestions[key]?.text}
                                                            margin="normal"
                                                            required={companyQuestions[key]?.required}
                                                        />
                                                    }
                                                />

                                                {values?.region?.label === "Skåne" ? (
                                                    <Autocomplete
                                                        disablePortal
                                                        autoComplete
                                                        value={values[companyQuestions[key]?.keyScania] || null}
                                                        options={companyQuestions[key]?.optionsScania}
                                                        onChange={(event, newValue) => {
                                                            handleChangeCounty(newValue);
                                                        }}
                                                        fullWidth
                                                        renderInput={(params) => <TextField {...params} label={companyQuestions[key]?.textScania} required={companyQuestions[key]?.required} margin="normal" />}

                                                    />
                                                ) : values?.region?.label === "Outside Sweden" ? (
                                                    <Autocomplete
                                                        disablePortal
                                                        autoComplete
                                                        value={values[companyQuestions[key]?.keyOutsideSweden] || null}
                                                        options={companyQuestions[key]?.optionsOutsideSweden}
                                                        onChange={(event, newValue) => {
                                                            handleChangeCountry(newValue);
                                                        }}
                                                        fullWidth
                                                        renderInput={(params) => <TextField {...params} label={companyQuestions[key]?.textOutsideSweden} required={companyQuestions[key]?.required} margin="normal" />}
                                                    />
                                                ) : ("")}
                                            </Grid>
                                        )
                                    }
                                    else if (companyQuestions[key]?.type === 'datePickerYear') {
                                        return (
                                            <Grid key={key} item xs={12} sm={12} md={6}>
                                                <DesktopDatePicker

                                                    views={['year']}
                                                    label={companyQuestions[key]?.text}
                                                    format="YYYY"
                                                    value={year ? dayjs(year) : null}
                                                    disableFuture
                                                    onChange={onChangeYear}
                                                    slotProps={{ textField: { helperText: null, margin: 'normal', required: companyQuestions[key]?.required, fullWidth: true } }}
                                                />
                                            </Grid>
                                        )
                                    }
                                })}
                            </>
                        ) : ("")}
                    </Grid>

                    <Divider />
                    <div className="backConfirm">
                        <FormHelperText error={isGeneralInvalid}>{isGeneralInvalid === true ? "Please fill the required fields." : ""}</FormHelperText>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        {isLoading ? (
                            <Box sx={{ maxWidth: '310px', minWidth: '275px' }}>
                                <LinearProgress color="info" />
                            </Box>
                        ) : (
                            <Box sx={{ marginBottom: "4px" }}>

                            </Box>
                        )}
                    </div>

                    <div className="backConfirm" style={{ paddingBottom: '10px' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={saveProgress}
                            disabled={isLoading || contactingKrinova == null || contactingKrinova === '' || ((existingCompany == null || existingCompany === '') && contactingKrinova !== contactingChoices.business.key)}
                        >
                            Save
                        </Button>

                        <Button
                            style={{ marginLeft: 20 }}
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={isLoading || contactingKrinova == null || contactingKrinova === '' || ((existingCompany == null || existingCompany === '') && contactingKrinova !== contactingChoices.business.key)}
                        >
                            Save & Continue
                        </Button>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: '10px', color: '#28a745' }}>
                        <i>{autoSave !== '' ? ('Last save: ' + autoSave) : ('')}</i>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: '10px' }}>
                        <img src={textBubble} alt="Tip! You can save and resume your sign up at a later time." height={'190px'} />
                    </div>
                </form>
            </LocalizationProvider>

            <ErrorAlert isError={error} setIsError={setError} errorMsg={errorMsg} errorInfo={errorInfo} />
        </>
    )
}
