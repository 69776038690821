import { useEffect, useState } from "react"
import { SETTINGSTEXT as SETTINGSTEXT_SWE} from "../../constants/lang/swe/startPageIBO";
import { SETTINGSTEXT as SETTINGSTEXT_ENG } from "../../constants/lang/eng/startPageIBO";
import useGetUserData from "../userData/useGetUserData";

export const useGetSettingsTexts = () => {
    const { userData, loading } = useGetUserData();
    
    const [sentences, setSentences] = useState({
        deadline: "",
        notificationsHeader: "",
        notificationsLabel: "",
        unlocked: "",
        oneWeek: "",
        oneDay: "",
        reviewed: "",
        meetingApproved: "",
        profileLink: "",
        miroLink: "",
        noMiroLink: "",
        addPatricipant: "",
    });

    useEffect(() => {
        if (loading === false) {
            /*
            // @ts-ignore
            if (userData?.prefLang === 'swe') {
                setSentences(SETTINGSTEXT_SWE)
            }
            else {
                setSentences(SETTINGSTEXT_ENG)
            }*/
            setSentences(SETTINGSTEXT_ENG)
        }
        else {
            setSentences(SETTINGSTEXT_ENG)
        }
    }, [loading, userData]);

    return sentences;
}