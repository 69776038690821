import { useEffect, useState } from "react"
import Grading from "../Grading/Grading"
import { useAuth } from "../../contexts/AuthContext";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, CircularProgress, Divider, FormControlLabel, Stack, Switch } from "@mui/material";

import { TrackLabels } from "../../constants/labels/labels";
import { BDInfoBox } from "../HomeIBO/components/BDInfoBox";
import { StepperTabComponentStart } from "../Stepper/StepperTabs/StepperTabComponentStart";
import { StepperTabComponentIncubator } from "../Stepper/StepperTabs/StepperTabComponentIncubator";
import { StepperTabComponentScale } from "../Stepper/StepperTabs/StepperTabComponentScale";
import { MatrixGrading } from "../MatrixGrading/MatrixGrading";
import { useGetOldGrading } from "../../hooks/grading/useGetOldGrading";
import { useGetGradingTrackConfigurations } from "../../hooks/grading/useGetGradingTrackConfigurations";
import { upgradeGradingToNew } from "../../firebase/cloudFunctions";
import { useConfirm } from "material-ui-confirm";


export const MatrixStart = () => {
    const [useNew, setUseNew] = useState(true);
    const [oldEditable, setOldEditable] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const { currentUser } = useAuth();
    const [projectData, setProjectData] = useState(Object)
    const oldGradeList = useGetOldGrading(projectData?.id);
    const { trackConfigurations, loading: loadingConfigs } = useGetGradingTrackConfigurations(projectData?.id);

    const [update, setUpdate] = useState(false);

    const location = useLocation();
    const navigate = useNavigate();

    const confirmConvert = useConfirm();

    useEffect(() => {
        if (loadingConfigs === false) {
            if ((trackConfigurations == null || Object.keys(trackConfigurations).length <= 0) && oldGradeList.length > 0) {
                setUseNew(false);
                setOldEditable(true);
            }
        }
    }, [loadingConfigs, oldGradeList, trackConfigurations])

    useEffect(() => {
        if (currentUser != null) {
            if (currentUser.role === 2 || currentUser.role === 3) {
                if (location.state != null && location.state.projectData != null) {
                    setProjectData(location.state.projectData);
                    setIsLoading(false);
                }
                else {
                    navigate("/home-bd", { replace: true });
                }
            }
            else {
                navigate("/home", { replace: true });
            }
        }
    }, [projectData]);


    const handleSwitchUseNew = () => {
        if (useNew === false) {
            if ((trackConfigurations == null || Object.keys(trackConfigurations).length <= 0) && projectData?.activeTrack !== TrackLabels.scale.key && projectData?.activeTrack !== TrackLabels.incubator.key) {
                if (oldGradeList.length > 0) {
                    console.log("Trigger here: ", projectData.id);
                    confirmConvert({ title: 'Convert grading to matrix 2.0', description: 'Do you want to convert old grading to matrix 2.0?', confirmationText: 'Yes', cancellationText: 'No' }).then(() => {
                        upgradeGradingToNew({
                            projectID: projectData.id,
                        }).then(() => {
                            setUpdate(!update);
                            setOldEditable(false);
                        }).catch((error) => {
                            console.error("FB Error: ", error);
                        });
                        setUseNew(true);
                    }).catch(() => {
                        setUseNew(true);
                    });
                }
            }
            else{
                setUseNew(true);
            }
        }
        else {
            setUseNew(false);
        }
    }

    /**
     * 
     * @param {('start'|'incubator'|'scale')} track 
     */
    const handleOpenTrack = (track) => {
        navigate("/home#step1", { state: { projectData: projectData, track: track } });
    }

    /**
     * 
     * @param {String} href 
     */
    function onClickStep(href) {
        if (href.startsWith('#')) {
            navigate('/home' + href, { state: { projectData: projectData } })
        }
        else {
            navigate(href, { state: { projectData: projectData } })
        }
    }

    if (isLoading) {
        return <div style={{ display: 'flex', justifyContent: 'center' }}><CircularProgress /></div>
    }

    const displayStepper = () => {
        switch (projectData.activeTrack) {
            case TrackLabels.start.key:
                return (<StepperTabComponentStart projData={projectData} value={0} onClickStep={onClickStep} />);
            case TrackLabels.incubator.key:
                return (<StepperTabComponentIncubator projData={projectData} value={0} onClickStep={onClickStep} />)
            case TrackLabels.scale.key:
                return (<StepperTabComponentScale projData={projectData} value={0} onClickStep={onClickStep} />)
            default:
                return (<StepperTabComponentStart projData={projectData} value={0} onClickStep={onClickStep} />);
        }
    }


    return (
        <div style={{ width: '100%' }}>
            {displayStepper()}
            <Stack direction={'row'} justifyContent={'space-between'}>
                <Box className='bd-info-box'>
                    <BDInfoBox projectData={projectData} />
                </Box>
            </Stack>

            <Divider sx={{ margin: "5px 0px", paddingTop: '40px' }}>Grading Matrix</Divider>

            <Box>
                {oldGradeList.length > 0 ? (
                    <Box display={'flex'} justifyContent={'right'}>
                        <FormControlLabel control={<Switch size="small" checked={useNew} onChange={handleSwitchUseNew} />} label={<Box sx={{ fontSize: '0.8rem' }}>Matrix 2.0</Box>} />
                    </Box>
                ) : (
                    <Box height={'15px'}></Box>
                )}
                {useNew ? (
                    <MatrixGrading project={projectData} triggerUpdate={update} />
                ) : (
                    <Grading projectData={projectData} isEditable={oldEditable} />
                )}

            </Box>

        </div >
    )
}