import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { getAnalytics, logEvent } from "firebase/analytics";
import { useEffect, useState } from "react";
import { CompleteProjectInfoIncubator } from "../UpdateData/CompleteProjectInfoIncubator";
import useGetUserProject from "../../hooks/userData/useGetUserProject";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { TrackLabels } from "../../constants/labels/labels";


export const QuarterReviewPage = () => {
    const { project, loading } = useGetUserProject();
    const [update, setUpdate] = useState(false);
    const { currentUser } = useAuth();
    const [pageLoading, setPageLoading] = useState(true);
    const analytics = getAnalytics();
    const navigate = useNavigate();

    useEffect(() => {
        if (loading === false) {
            if (project.data.isActive != null && project.data.isActive !== true) {
                navigate("/inactive", { replace: true });
            }
            else if (project?.data?.activeTrack !== TrackLabels.incubator.key && project?.data?.activeTrack !== TrackLabels.scale.key) {
                navigate('/home', { replace: true });
            }

            setPageLoading(false);
        }
    }, [loading, project, update])

    useEffect(() => {
        logEvent(analytics, 'page_view', {
            page_title: 'Quarter Review Page',
            page_path: window.location.pathname,
        });
    }, [])

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Stack justifyContent="center" alignItems="center" spacing={2} sx={{ marginTop: '50px' }}>
                {pageLoading ? (
                    <Box>
                        <CircularProgress />
                    </Box>
                ) : (
                    <>
                        {currentUser?.userInfo?.prefLang === 'swe' ? (
                            <>
                                <Box style={{ margin: "30px 0 50px" }}>
                                    <Typography variant="h4" align="center">
                                        Kvartalsgranskning
                                    </Typography>
                                    <Typography
                                        variant="subtitle2"
                                        align="center"
                                        style={{ margin: "10px 0" }}
                                    >
                                        Läs igenom dina tidigare svar och uppdatera eventuell information som saknas eller inte längre är aktuell.
                                    </Typography>
                                </Box>
                            </>
                        ) : (
                            <>
                                <Box style={{ margin: "30px 0 50px" }}>
                                    <Typography variant="h4" align="center">
                                        Periodic review
                                    </Typography>
                                    <Typography
                                        variant="subtitle2"
                                        align="center"
                                        style={{ margin: "10px 0" }}
                                    >
                                        Please read through your previous answers and update any information that is missing or not up to date.
                                    </Typography>
                                </Box>
                            </>
                        )}

                        <CompleteProjectInfoIncubator quarterReviewPage projectData={project} update={update} setUpdate={setUpdate} />
                    </>
                )}

            </Stack>

        </Box>
    )
}