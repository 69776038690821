import { httpsCallable } from 'firebase/functions';
import { functionsEUWest } from '../firebase';

//Project
export const createNewProject = httpsCallable(functionsEUWest, 'createNewProject');                                         //V2
export const addProjectInfo = httpsCallable(functionsEUWest, 'addProjectInfo');                                             //V2
export const addMiroLink = httpsCallable(functionsEUWest, 'addMiroLink');                                                   //V2
export const addUseToProj = httpsCallable(functionsEUWest, 'addParticipantToProj');                                         //V2
export const deleteProject = httpsCallable(functionsEUWest, 'deleteProject');                                               //V2
export const updateCompInfo = httpsCallable(functionsEUWest, 'updateCompInfo');                                             //V2
export const assignBDToProj = httpsCallable(functionsEUWest, "assignBDToProj");                                             //V2
export const setActiveStatus = httpsCallable(functionsEUWest, 'setActiveStatus');                                           //V2
export const setActiveTrack = httpsCallable(functionsEUWest, 'setActiveTrack');                                             //V2
export const setAlreadyBookedFM = httpsCallable(functionsEUWest, 'setAlreadyBookedFM');                                     //V2
export const confirmBookedFM = httpsCallable(functionsEUWest, 'confirmBookedFM');                                           //V2
export const rejectBookedFM = httpsCallable(functionsEUWest, 'rejectBookedFM');                                             //V2
export const confirmQuarterData = httpsCallable(functionsEUWest, 'confirmQuarterData');                                     //V2
export const confirmTrackChangeAndAssignBD = httpsCallable(functionsEUWest, 'confirmTrackChangeAndAssignBD');               //V2
export const confirmExitTrack = httpsCallable(functionsEUWest, 'confirmExitTrack');                                         //V2
export const completeProjectInfo = httpsCallable(functionsEUWest, 'completeProjectInfo');
export const completeProjectInfoIncubator = httpsCallable(functionsEUWest, 'completeProjectInfoIncubator');
export const approveAnswersIncubator = httpsCallable(functionsEUWest, 'approveAnswersIncubator');

//UserData
export const setEmailPrefMulti = httpsCallable(functionsEUWest, 'setEmailPrefMulti');                                       //V2
export const setEmailPref = httpsCallable(functionsEUWest, 'setEmailPref');                                                 //V2
export const createBDUser = httpsCallable(functionsEUWest, 'createBDUser');                                                 //V2
export const changeGreetingPhrase = httpsCallable(functionsEUWest, 'changeGreetingPhrase');                                 //V2
export const changeTitle = httpsCallable(functionsEUWest, 'changeTitle');                                                   //V2
export const editBDUser = httpsCallable(functionsEUWest, 'editBDUser');                                                     //V2
export const updateDefaultTableSettings = httpsCallable(functionsEUWest, 'updateDefaultTableSettings');                     //V2
export const createUserData = httpsCallable(functionsEUWest, 'createUserData');                                             //V2
export const deactivateUser = httpsCallable(functionsEUWest, 'deactivateUser');                                             //V2
export const updateUserData = httpsCallable(functionsEUWest, 'updateUserData');                                             //V2
export const updateLanguage = httpsCallable(functionsEUWest, 'updateLanguage');                                             //V2
export const deleteBDUser = httpsCallable(functionsEUWest, 'deleteBDUser');                                                 //V2

//Timeslots
export const bookTimeSlot = httpsCallable(functionsEUWest, 'bookTimeSlot');                                                 //V2
export const createTimeSlot = httpsCallable(functionsEUWest, 'createTimeSlot');                                             //V2
export const deleteTimeSlot = httpsCallable(functionsEUWest, 'deleteTimeSlot');                                             //V2

//Steps
export const setNextTimeStep = httpsCallable(functionsEUWest, 'setNextTimeStep');                                           //V2
export const setStepState = httpsCallable(functionsEUWest, 'setStepState');                                                 //V2
export const complemendStep = httpsCallable(functionsEUWest, 'complemendStep');                                             //V2
export const entryStepQuestions = httpsCallable(functionsEUWest, 'entryStepQuestions');                                     //V2
export const entryStepQuestions_save = httpsCallable(functionsEUWest, 'entryStepQuestions_save');                           //V2
export const updateDeadlineStep = httpsCallable(functionsEUWest, 'updateDeadlineStep');                                     //V2

//Templates
export const createTemplate = httpsCallable(functionsEUWest, 'createTemplate');                                             //V2
export const assignMultipleTemplates = httpsCallable(functionsEUWest, 'assignMultipleTemplates');                           //V2
export const editTemplate = httpsCallable(functionsEUWest, 'editTemplate');                                                 //V2
export const deleteTemplate = httpsCallable(functionsEUWest, 'deleteTemplate');                                             //V2

//Notes
export const writeNote = httpsCallable(functionsEUWest, 'writeNote');                                                       //V2
export const deleteNote = httpsCallable(functionsEUWest, 'deleteNote');                                                     //V2
export const editNote = httpsCallable(functionsEUWest, 'editNote');                                                         //V2
export const addNoteMetaData = httpsCallable(functionsEUWest, 'addNoteMetaData');                                           //V2

//Questions
export const rectifyQuestion = httpsCallable(functionsEUWest, 'rectifyQuestion');                                           //V2
export const editQuestion = httpsCallable(functionsEUWest, 'editQuestion');                                                 //V2
export const createQuestion = httpsCallable(functionsEUWest, 'createQuestion');                                             //V2
export const deleteQuestion = httpsCallable(functionsEUWest, 'deleteQuestion');                                             //V2
export const answerQuestion = httpsCallable(functionsEUWest, 'answerQuestion');                                             //V2
export const answerQuestions = httpsCallable(functionsEUWest, 'answerQuestions');                                           //V2

//Mail
export const sendInvitationalEmail = httpsCallable(functionsEUWest, 'sendInvitationEmail');                                 //V2

//Grading
export const addGradingToProj = httpsCallable(functionsEUWest, 'addGradingToProj');                                         //V2

export const addStartingLevelsFunc = httpsCallable(functionsEUWest, 'addStartingLevels');                                   //V2
export const addTargetLevelsFunc = httpsCallable(functionsEUWest, 'addTargetLevels');                                       //V2
export const addMilestoneDataFunc = httpsCallable(functionsEUWest, 'addMilestoneData');                                     //V2
export const addMilestoneDataCellFunc = httpsCallable(functionsEUWest, 'addMilestoneDataCell');                             //V2
export const verifiedMilestonesFunc = httpsCallable(functionsEUWest, 'verifiedMilestones');                                 //V2
export const markGradingCellAsCompletedFunc = httpsCallable(functionsEUWest, 'markGradingCellAsCompleted');                 //V2
export const addSystemDefaultMilestoneDataCellFunc = httpsCallable(functionsEUWest, 'addSystemDefaultMilestoneDataCell');   //V2
export const upgradeGradingToNew = httpsCallable(functionsEUWest, 'upgradeGradingToNew');                                   //V2

//Statistics
export const gatherMeetingsStats = httpsCallable(functionsEUWest, 'gatherMeetingsStats');                                   //V2
export const gatherLastCompletedCheckpoint = httpsCallable(functionsEUWest, 'gatherLastCompletedCheckpoint');               //V2

//Files
export const uploadFile = httpsCallable(functionsEUWest, 'uploadFile');                                                     //V2
export const removeFile = httpsCallable(functionsEUWest, 'removeFile');                                                     //V2
export const setFileStatus = httpsCallable(functionsEUWest, 'setFileStatus');                                               //V2

//TimeLogs
export const addNewTimeLog = httpsCallable(functionsEUWest, 'addNewTimeLog');                                               //V2

//Settings
export const setDefaultValues = httpsCallable(functionsEUWest, 'setDefaultValues');                                         //V2

//Extra Functions
export const correctUserCites = httpsCallable(functionsEUWest, 'correctUserCites');                                         //V2
export const bulkUpdateActivityTrack = httpsCallable(functionsEUWest, 'bulkUpdateActivityTrack');                           //V2