import { Box, Divider, Grid, Stack, Tooltip } from "@mui/material";
import { useMemo } from "react";
import { CompanyQuestionsSwe, CompanyQuestionsEng, ExtendedCompanyQuestionsSwe, ExtendedCompanyQuestionsEng } from "../../constants/companyQuestions/companyQuestions";
import { BusinessIdeasQuestionsNewCompSwe, BusinessIdeasQuestionsNewCompEng } from "../../constants/ideasQuestions/businessIdeaQuestions";
import { useAuth } from "../../contexts/AuthContext";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { OwnershipDisplayComponent } from "../ExtendedCompanyQuestions/CompanyOwnership/OwnershipDisplayComponent";
import { CompanyStateAidDisplayComponent } from "../ExtendedCompanyQuestions/CompanyStateAid/CompanyStateAidDisplayComponent";

import OutlinedFlagRoundedIcon from '@mui/icons-material/OutlinedFlagRounded';

export const IncubatorAnswerContent = ({ projData, questionList }) => {
    const { currentUser } = useAuth();

    const CompanyQuestions = useMemo(() => {
        if (currentUser?.userInfo?.prefLang === 'swe') {
            return { ...CompanyQuestionsSwe, ...ExtendedCompanyQuestionsSwe };
        }
        else {
            return { ...CompanyQuestionsEng, ...ExtendedCompanyQuestionsEng };
        }
    }, [currentUser]);


    const BusinessQuestions = useMemo(() => {
        if (currentUser?.userInfo?.prefLang === 'swe') {
            return BusinessIdeasQuestionsNewCompSwe;
        }
        else {
            return BusinessIdeasQuestionsNewCompEng;
        }
    }, [currentUser]);


    const displayAnswer = (key, compData) => {
        if (compData != null) {
            switch (key) {
                case 'region':
                    if (compData['compCity'] != null) {
                        return displayCompCity(compData['compCity']);
                    }
                    else {
                        return "Not Answered";
                    }
                case 'compOwnership':
                    if (compData['compOwnership'] != null) {
                        return (<OwnershipDisplayComponent answerData={compData['compOwnership']} />);
                    }
                    else {
                        return "Not Answered";
                    }
                case 'compStateAidValue':
                    if (compData['compStateAidValue'] != null) {
                        return (<CompanyStateAidDisplayComponent answerData={compData['compStateAidValue']} />)
                    }
                    else {
                        return "Not Answered";
                    }
                case 'compStateAid':
                    if (compData['compStateAid'] != null) {
                        let option = CompanyQuestions.compStateAid.options.find(element => element.value === compData['compStateAid']);
                        if (option != null) {
                            return option.label;
                        }
                        else {
                            return compData['compStateAid'];
                        }
                    }
                    else {
                        return "Not Answered";
                    }
                default:
                    if (compData[key] != null) {
                        return compData[key];
                    }
                    else {
                        return "Not Answered";
                    }
            }
        }
        else {
            return "No Data!";
        }
    }

    const getBusinessIdeaAnswer = (key) => {
        if (questionList != null) {
            const questionData = questionList.find((item) => item.id === key);
            if (questionData?.answerData?.answer != null) {
                return questionData?.answerData?.answer;
            }
        }

        return "";
    }

    const displayBusinessIdeaAnswer = (key) => {
        if (questionList != null) {
            const questionData = questionList.find((item) => item.id === key);
            if (questionData?.answerData?.answer != null) {
                if (key === 'workingInGroup') {
                    let option = BusinessQuestions.workingInGroup.options.find(element => element.value === questionData?.answerData?.answer);
                    if (option != null) {
                        return option.label;
                    }
                    else {
                        return questionData?.answerData?.answer;
                    }
                }
                if (key === 'foodConnection') {
                    let option = BusinessQuestions.foodConnection.options.find(element => element.value === questionData?.answerData?.answer);
                    if (option != null) {
                        return option.label;
                    }
                    else {
                        return questionData?.answerData?.answer;
                    }
                }
                else {
                    return questionData?.answerData?.answer;
                }
            }
            else {
                return "Not Answered";
            }

        }
        else {
            return "No Data!";
        }
    }

    const displayCompCity = (regionAnswer) => {
        if (regionAnswer != null) {
            if (String(regionAnswer).includes(';')) {
                let cityData = String(regionAnswer).split(';');
                if (cityData[0] != null && cityData[0] !== '') {
                    return String(cityData[0] + " - " + cityData[1]);
                }
                else {
                    return String(cityData[1]);
                }
            }
            else {
                return String(regionAnswer);
            }
        }
        else {
            return "Not answered!";
        }
    }

    const highlightAnswer = (key) => {
        const currentDate = new Date();

        if (key === CompanyQuestions.founded.key) {
            let foundedDate = new Date();
            if (projData?.companyData?.founded != null) {
                foundedDate.setFullYear(projData?.companyData?.founded);

                if (currentDate.getFullYear() - foundedDate.getFullYear() > 5) {
                    return "Company is older than 5 years.";
                }
            }
            else {
                return "Year founded not set.";
            }

        }
        else if (key === CompanyQuestions.compOwnership.key) {
            let flaggedIssues = [];
            let totalOwnershipFounders = 0;
            let totalCommitment = 0;
            if (projData?.companyData?.compOwnership != null) {
                for (let index = 0; index < projData?.companyData?.compOwnership.length; index++) {
                    const element = projData?.companyData?.compOwnership[index];

                    if (element.ownerType === 'privateFounder' || element.ownerType === 'compFounder') {
                        totalOwnershipFounders += element.procent;
                        totalCommitment += element.comittment;

                        if (element.comittment == null || element.comittment < 40) {
                            flaggedIssues.push("An owner has a time commitment less than 40% of full time.");
                        }
                    }
                }
            }


            if (totalOwnershipFounders < 75) {
                flaggedIssues.push("Total ownership for founders is less than 75%.");
            }

            if (totalCommitment < 60) {
                flaggedIssues.push("Total time commitment for founders is less than 60% of full time.");
            }

            if (flaggedIssues.length > 0) {
                return flaggedIssues.join(" ");
            }
        }
        else if (key === CompanyQuestions.compStateAidValue.key) {
            let totalSum = 0;
            let dateDeadline = new Date();
            dateDeadline.setFullYear(dateDeadline.getFullYear() - 3);

            if (projData?.companyData?.compStateAidValue != null) {
                for (let index = 0; index < projData?.companyData?.compStateAidValue.length; index++) {
                    const element = projData?.companyData?.compStateAidValue[index];
                    let foundingDate = new Date(element.date);

                    if (foundingDate >= dateDeadline) {
                        element.amount += totalSum;
                    }
                }
            }


            if (totalSum > 300000) {
                return "Total sum of state aid is more than 300 000 EUR";
            }
        }
        else if (key === BusinessQuestions.foodConnection.key) {
            if (getBusinessIdeaAnswer(key) === 'no') {
                return "No connection to food industry";
            }
        }

        return "";
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box height={'10px'} width={'100%'}>
                    <Divider><strong>{currentUser?.userInfo?.prefLang === 'swe' ? ("Företagsinfo") : ("Company info")}</strong></Divider>
                </Box>
                <Grid container spacing={3} justifyContent="center" padding={2}>
                    {Object.keys(CompanyQuestions).map((key) => {
                        if ((CompanyQuestions[key]?.requiredKey != null && CompanyQuestions[key]?.requiredKeyValue === projData?.companyData[CompanyQuestions[key]?.requiredKey]) || CompanyQuestions[key]?.requiredKey == null) {
                            return (
                                <Grid key={key} item xs={12} sm={12} md={5.5} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                    <Stack sx={{ border: '1px solid #4eacc160', borderRadius: '4px', padding: '10px' }} width={'100%'} direction={'row'} spacing={1}>
                                        <Stack width={'100%'}>
                                            <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                                {highlightAnswer(key) !== "" ? (
                                                    <Tooltip title={highlightAnswer(key)} arrow placement="top">
                                                        <Box>
                                                            <OutlinedFlagRoundedIcon fontSize="small" htmlColor="#db0000" />
                                                        </Box>
                                                    </Tooltip>
                                                ) : ("")}
                                                <Box fontWeight={700}>
                                                    {CompanyQuestions[key]?.label}
                                                </Box>

                                            </Stack>

                                            <Box>
                                                {displayAnswer(key, projData?.companyData)}
                                            </Box>
                                        </Stack>
                                    </Stack>

                                </Grid>
                            );
                        }
                    })}
                </Grid>
                <Box height={'10px'} width={'100%'}>
                    <Divider><strong>Business idea</strong></Divider>
                </Box>

                <Grid container spacing={1} justifyContent="center" padding={1}>
                    {Object.keys(BusinessQuestions).map((key) => {
                        if ((BusinessQuestions[key]?.requiresKey != null && getBusinessIdeaAnswer(key) === BusinessQuestions[key]?.requiresVal) || BusinessQuestions[key]?.requiresKey == null) {
                            return (
                                <Grid key={key} item xs={12} sm={12} md={5.5} sx={{ display: 'flex', justifyContent: 'flex-start', marginTop: '15px', marginBottom: '5px' }}>
                                    <Stack sx={{ border: '1px solid #4eacc160', borderRadius: '4px', padding: '10px' }} width={'100%'} direction={'row'} spacing={1}>
                                        <Stack width={'100%'}>
                                            <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                                {highlightAnswer(key) !== "" ? (
                                                    <Tooltip title={highlightAnswer(key)} arrow placement="top">
                                                        <Box>
                                                            <OutlinedFlagRoundedIcon fontSize="small" htmlColor="#db0000" />
                                                        </Box>
                                                    </Tooltip>
                                                ) : ("")}
                                                <Box fontWeight={700}>
                                                    {BusinessQuestions[key]?.text}
                                                </Box>

                                            </Stack>

                                            <Box>
                                                {displayBusinessIdeaAnswer(key)}
                                            </Box>
                                        </Stack>
                                    </Stack>
                                </Grid>
                            );
                        }

                    })}
                </Grid>
            </LocalizationProvider>
        </Box>
    )
};