import { Box, Button, DialogActions, DialogContent, Divider, IconButton, LinearProgress, Modal, Stack } from "@mui/material"
import { BootstrapDialog, BootstrapDialogTitle } from "../../helper/bootstrapDialog"
import { IncubatorAnswerContent } from "./IncubatorAnswerContent"
import { useGetProjectEntryQuestions } from "../../hooks/projectData/useGetProjectEntryQuestions";

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { LoadingButton } from "@mui/lab";

export const IncubatorActivitionReviewModal = ({ projData, open, handleClose, handleApprove, submittingData }) => {
    const { questionList, isLoading: loadingQuestions } = useGetProjectEntryQuestions(projData?.id);

    return (
        <Modal
            onClose={handleClose}
            open={open}
        >
            <Box className="update-ideas">
                <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                    <h6><strong>Review Answers</strong></h6>
                    <IconButton style={{ float: 'right', cursor: 'pointer', marginTop: '-20px' }} onClick={handleClose}>
                        <CloseRoundedIcon />
                    </IconButton>
                </Stack>
                <Divider sx={{ borderColor: '#4eacc1' }} />
                <Box sx={{ overflowY: 'scroll', height: '80vh' }}>
                    <IncubatorAnswerContent projData={projData} questionList={questionList} />

                    <Divider sx={{ borderColor: '#4eacc1' }} />
                    <DialogActions sx={{ paddingRight: '30px'}}>
                        <Button onClick={handleClose} sx={{ marginBottom: '10px' }} variant="contained" color="primary">
                            Close
                        </Button>
                        <LoadingButton loading={submittingData} onClick={handleApprove} sx={{ marginBottom: '10px' }} variant="contained" color="primary">
                            Approve
                        </LoadingButton>
                    </DialogActions>
                </Box>

            </Box>
        </Modal>
    )
}