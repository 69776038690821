import { useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from "../../contexts/AuthContext"
import useGetUserProject from '../../hooks/userData/useGetUserProject'
import CircularProgress from '@mui/material/CircularProgress'
import useGetUserData from '../../hooks/userData/useGetUserData'

import { useEffect, useState } from 'react'
import { StartStepperContent } from '../Stepper/Start/StartStepperComponent'
import { IncubatorStepperContent } from '../Stepper/Incubator/IncubatorStepperContent'
import { ScaleStepperContent } from '../Stepper/Scale/ScaleStepperContent'
import { ProcessStepLabels } from '../../constants/labels/labels'

const Home = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [selectedDisplayTrack, setSelectedDisplayTrack] = useState('');
    const { project, loading } = useGetUserProject()
    //const { userData, loadingUser } = useGetUserData();
    const { currentUser } = useAuth();
    const { userData, loading: loadingData } = useGetUserData();
    const [projectData, setProjectData] = useState(Object)
    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        setIsLoading(true)
        if (currentUser != null) {
            if (currentUser.role === 2 || currentUser.role === 3) {
                if (location.state != null && location.state.projectData != null) {
                    setProjectData(location.state.projectData);
                    if (location.state.track != null) {
                        setSelectedDisplayTrack(location.state.track);
                    }
                    else if (location.state.projectData?.activeTrack != null) {
                        setSelectedDisplayTrack(location.state.projectData?.activeTrack);
                    }
                    setIsLoading(false);
                }
                else {
                    navigate("/home-bd", { replace: true });
                }
            }
            else {
                if (loadingData === false) {
                    if (userData != null && Object.keys(userData).length > 0) {
                        // @ts-ignore
                        if (userData.isSecondary === true) {
                            // @ts-ignore
                            if (userData.dataSet === 0) {
                                navigate("/invited", { replace: true });
                            }
                        }
                        // @ts-ignore
                        else if (userData.dataSet == null || userData.dataSet !== 5) {
                            navigate("/set-up-account", { replace: true });
                        }

                        if (project != null && project.data != null) {
                            // @ts-ignore
                            setProjectData({
                                id: project.id,
                                value: project?.data?.projectName,
                                miroLink: project?.data?.miroLink,
                                IBOOwner: project?.data?.IBOOwner,
                                nextDeadline: project?.data?.nextDeadline,
                                existingComp: project?.data?.existingComp,
                                website: project?.data?.website,
                                activeTrack: project?.data?.activeTrack,
                                trackParticipation: project?.data?.trackParticipation,
                            });

                            //console.log("Hello? 2: ", project?.data, project?.data?.activeTrack === 'incubator')
                            if (project.data.isActive != null && project.data.isActive !== true) {
                                navigate("/inactive", { replace: true });
                            }
                            else if (project?.data?.currentStep === ProcessStepLabels.PreMeet.key && project?.data?.alreadyBookedFM === true){
                                navigate("/awaiting-confirm", { replace: true });
                            }
                            else if (project?.data?.needsCompletion === true){
                                if (project?.data?.activeTrack === 'scale') {
                                    navigate("/complete-info-scale", { replace: true });    
                                }
                                else if (project?.data?.activeTrack === 'incubator' && project?.data?.approvedData !== true){
                                    navigate("/complete-info-incubator", { replace: true });    
                                }
                            }
                            else if (project?.data?.activeTrack === 'incubator'){
                                if (project?.data?.confirmedQuarterData !== true){
                                    navigate("/quarter-review", { replace: true });
                                }
                            }

                            setIsLoading(false);
                        }
                    }
                }
            }
        }
        else {
            navigate("/login", { replace: true });
        }


    }, [loadingData, currentUser, loading, userData])

    useEffect(() => {
        if (projectData != null && projectData?.activeTrack != null && selectedDisplayTrack === "") {
            setSelectedDisplayTrack(projectData?.activeTrack);
        }
    }, [projectData]);

    if (isLoading) {
        return <div style={{ display: 'flex', justifyContent: 'center' }}><CircularProgress /></div>
    }

    const displayStepper = () => {
        switch (selectedDisplayTrack) {
            case "start":
                return <StartStepperContent project={projectData} />;
            case "incubator":
                return <IncubatorStepperContent project={projectData} />;
            case "scale":
                return <ScaleStepperContent project={projectData} />;
            default:
                return <StartStepperContent project={projectData} />;
        }
    }

    function handleChangeDisplayTrack(event) {
        setSelectedDisplayTrack(event.target.value);
        navigate(window.location.pathname, { state: { projectData: projectData } })
    }

    return (
        <>
            <div>
                {displayStepper()}
            </div>
        </>
    )
}

export default Home