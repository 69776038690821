import { Box, IconButton, Stack, Tooltip } from "@mui/material";
import { TrackLabels } from "../../../constants/labels/labels";
import { useGetBdOwners } from "../../../hooks/userData/useGetBdOwners";
import { TimeReportDialog } from "../../TimeLogs/TimeReportDialog";
import CommentDialogLog from "../../Comments/CommentDialogLogInternal";
import CommentDialogLogExternal from "../../Comments/CommentDialogLogExternal";
import { useGetProjectSettingsData } from "../../../hooks/settings/useGetProjectSettingsData";
import { FileOverview } from "../../Files/FileOverview";
import { useEffect, useMemo, useState } from "react";

import PlayForWorkIcon from '@mui/icons-material/PlayForWork';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { TrackSelectMenu } from "./TrackSelectMenu";

export const BDInfoBox = ({ projectData }) => {
    const bdowners = useGetBdOwners();
    const { projectSettings } = useGetProjectSettingsData();

    const [trackMenu, setTrackMenu] = useState([]);

    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = Boolean(anchorEl);

    const handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const projName = useMemo(() => {
        if (projectData?.companyData?.compName != null && projectData?.companyData?.compName !== "") {
            if (projectData?.existingComp === 'no' || projectData?.existingComp === 'No/Not yet') {
                return projectData?.value;
            }
            else {
                return projectData?.companyData?.compName;
            }

        }
        else {
            return projectData?.value;
        }
    }, [projectData]);


    useEffect(() => {
        if (projectData?.trackParticipation != null) {
            let localList = [];
            if (projectData?.trackParticipation[TrackLabels.start.key] != null && projectData?.activeTrack !== TrackLabels.start.key) {
                localList.push(TrackLabels.start.key);
            }

            if (projectData?.trackParticipation[TrackLabels.incubator.key] != null && projectData?.activeTrack !== TrackLabels.incubator.key) {
                localList.push(TrackLabels.incubator.key);
            }

            if (projectData?.trackParticipation[TrackLabels.scale.key] != null && projectData?.activeTrack !== TrackLabels.scale.key) {
                localList.push(TrackLabels.scale.key);
            }

            setTrackMenu(localList);
        }

    }, [projectData])

    const getBDName = () => {
        let name = "";
        if (projectData?.BDOwner != null) {
            if (typeof (projectData?.BDOwner) === 'string') {
                let bdInfo = bdowners.find((item) => item.id === projectData?.BDOwner)
                if (bdInfo != null && bdInfo.value != null) {
                    name = bdInfo.value;
                }
            }
            else if (Array.isArray(projectData?.BDOwner)) {
                for (let index = 0; index < projectData.BDOwner.length; index++) {
                    const owner = projectData.BDOwner[index];

                    let bdInfo = bdowners.find((item) => item.id === owner);

                    if (bdInfo != null && bdInfo.value != null) {
                        if (index === 0) {
                            name = bdInfo.value;
                        }
                        else {
                            name += bdInfo.value;
                        }

                        if (index + 1 < projectData.BDOwner.length) {
                            name += ', ';
                        }
                    }
                }
            }
        }

        return name;
    }

    return (
        <Box>
            <Stack spacing={2} sx={{ minWidth: '220px', maxWidth: '400px' }} direction={'row'} alignItems={'start'} justifyContent={'space-between'}>
                <Stack>
                    <div>
                        IBO: <strong>{projName}</strong>
                    </div>
                    {projectData?.activeTrack != null && TrackLabels[projectData?.activeTrack] != null ? (
                        <div>
                            Current track: <strong>{TrackLabels[projectData?.activeTrack]?.label}
                                {projectData?.trackParticipation == null || projectData?.trackParticipation[projectData?.activeTrack] == null || projectData?.trackParticipation[projectData?.activeTrack] === 0 ? (
                                    <> (Pending)</>
                                ) : ""}
                            </strong>

                            {trackMenu.length > 0 ? (
                                <Tooltip arrow placement="right" title={"Previous Tracks"}>
                                    <IconButton onClick={handleOpenMenu} sx={{ marginLeft: '5px', marginBottom: '2px', width: '22px', height: '22px' }} size="small">
                                        <KeyboardArrowDownRoundedIcon sx={{ height: '20px' }} />
                                    </IconButton>
                                </Tooltip>

                            ) : ("")}
                        </div>
                    ) : (
                        <div>
                            Current track: <strong><i> - </i></strong>
                        </div>
                    )}
                    <div>
                        BD: <strong>{getBDName()}</strong>
                    </div>


                    {projectData?.isActive === false ? (
                        <div style={{ color: 'grey' }}>
                            <i>Inactive</i>
                        </div>

                    ) : (
                        <div>
                            <strong>Active</strong>
                        </div>
                    )}

                    <Stack direction={'row'} alignItems={'center'}>
                        <CommentDialogLog projID={projectData.id} projData={projectData} />
                        <CommentDialogLogExternal projID={projectData.id} projData={projectData} />
                        <FileOverview projData={projectData} />
                        {projectData?.activeTrack === TrackLabels.incubator.key || projectData?.activeTrack === TrackLabels.scale.key ? (
                            <TimeReportDialog projData={projectData} projectSettings={projectSettings} />
                        ) : ("")}
                    </Stack>
                </Stack>
            </Stack>

            <TrackSelectMenu open={openMenu} handleClose={handleCloseMenu} anchorEl={anchorEl} options={trackMenu} project={projectData} />
        </Box>
    );
}