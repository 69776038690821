import { COUNTRY_LIST, COUNTY_LIST, REGION_LIST } from "../lang/general/regions";

export const contactingChoices = {
    newIdea: { key: "newIdea", label: "I have an innovative idea I want to explore", sweLabel: "Jag har en innovativ idé jag vill utforska" },
    business: { key: "business", label: "I have an established business that I wish to scale up", sweLabel: "Jag har ett etablerat bolag som jag vill skala upp" },
}


export const companyChoices = {
    no: { key: "no", label: "No/Not yet", sweLabel: "Nej/Inte än" },
    yes: { key: "yes", label: "Yes", sweLabel: "Ja" },
}

export const companyFormChoices = {
    "Enskild firma": { value: "Enskild firma", label: "Enskild firma (or equivalent)", sweLabel: "Enskild firma" },
    "Handelsbolag/Kommanditbolag": { value: "Handelsbolag/Kommanditbolag", label: "Handelsbolag/Kommanditbolag (or equivalent)", sweLabel: "Handelsbolag/Kommanditbolag" },
    "Aktiebolag": { value: "Aktiebolag", label: "Aktiebolag (or equivalent)", sweLabel: "Aktiebolag" },
}

export const ProjectStartQuestionsEng = {
    contactingKrinova: {
        key: 'contactingKrinova',
        type: 'radioGroup',
        order: 0,
        required: true,
        label: "Why are you contacting us at Krinova?",
        text: "Why are you contacting us at Krinova?",
        options: [
            { value: contactingChoices.newIdea.key, label: contactingChoices.newIdea.label },
            { value: contactingChoices.business.key, label: contactingChoices.business.label },
        ],
    },
    existingCompNewIdea: {
        key: 'existingCompNewIdea',
        type: 'radioGroup',
        order: 1,
        required: true,
        label: "Do you have a registered company based around this idea?",
        text: "Do you have a registered company based around this idea?",
        options: [
            { value: companyChoices.no.key, label: companyChoices.no.label },
            { value: companyChoices.yes.key, label: companyChoices.yes.label },
        ],
    }
}

export const ProjectStartQuestionsSwe = {
    contactingKrinova: {
        key: 'contactingKrinova',
        type: 'radioGroup',
        order: 0,
        required: true,
        label: "Varför kontaktar du oss på Krinova?",
        text: "Varför kontaktar du oss på Krinova?",
        options: [
            { value: contactingChoices.newIdea.key, label: contactingChoices.newIdea.sweLabel },
            { value: contactingChoices.business.key, label: contactingChoices.business.sweLabel },
        ],
    },
    existingCompNewIdea: {
        key: 'existingCompNewIdea',
        type: 'radioGroup',
        order: 1,
        required: true,
        label: "Har du ett registrerat företag baserat på idén?",
        text: "Har du ett registrerat företag baserat på idén?",
        options: [
            { value: companyChoices.no.key, label: companyChoices.no.sweLabel },
            { value: companyChoices.yes.key, label: companyChoices.yes.sweLabel },
        ],
    }
}

export const ProjectInfoQuestionsSwe = {
    projName: {
        key: 'projName',
        type: 'textField',
        required: true,
        order: 0,
        label: "Projektnamn",
        text: "Projektnamn",
    },
    website: {
        key: 'website',
        type: 'textField',
        required: false,
        order: 1,
        label: "Hemsida",
        text: "Hemsida (valfritt)",
    },
}

export const ProjectInfoQuestionsEng = {
    projName: {
        key: 'projName',
        type: 'textField',
        required: true,
        order: 0,
        label: "Project name",
        text: "Project name",
    },
    website: {
        key: 'website',
        type: 'textField',
        required: false,
        order: 1,
        label: "Website",
        text: "Website (if applicable)",
    },
}

export const CompanyQuestionsSwe = {
    compForm: {
        key: 'compForm',
        type: 'radioGroup',
        required: true,
        order: 2,
        label: "Bolagsform",
        text: "Bolagsform",
        options: [
            { value: companyFormChoices["Enskild firma"].value, label: companyFormChoices["Enskild firma"].sweLabel },
            { value: companyFormChoices["Handelsbolag/Kommanditbolag"].value, label: companyFormChoices["Handelsbolag/Kommanditbolag"].sweLabel },
            { value: companyFormChoices["Aktiebolag"].value, label: companyFormChoices["Aktiebolag"].sweLabel },
        ],
    },
    compName: {
        key: 'compName',
        type: 'textField',
        required: true,
        order: 3,
        label: "Företagsnamn",
        text: "Företagsnamn",
    },
    website: {
        key: 'website',
        type: 'textField',
        required: false,
        order: 1,
        label: "Hemsida",
        text: "Hemsida (valfritt)",
    },
    orgNum: {
        key: 'orgNum',
        type: 'textField',
        order: 4,
        required: true,
        label: "Organisationsnummer",
        text: "Organisationsnummer",
    },
    region: {
        key: 'region',
        keyScania: "county",
        keyOutsideSweden: "country",
        required: true,
        type: 'autoCompleteRegion',
        order: 5,
        label: "Huvudsäte",
        text: "Region (säte om flera)",
        textScania: "Kommun",
        textOutsideSweden: "Land",
        options: REGION_LIST,
        optionsScania: COUNTY_LIST,
        optionsOutsideSweden: COUNTRY_LIST,
    },
    founded: {
        key: 'founded',
        type: 'datePickerYear',
        required: true,
        order: 6,
        label: "Registreringsår",
        text: "Registreringsår",
    },
    compRole: {
        key: 'compRole',
        type: 'textField',
        required: true,
        order: 7,
        label: "Roll i företaget",
        text: "Din roll i företaget",
    },
    revenue: {
        key: 'revenue',
        type: 'radioGroup',
        required: true,
        order: 9,
        label: "Omsättning",
        text: "Omsättning (senaste bokslut)",
        options: [
            { value: "< 500k SEK", label: "< 500k SEK" },
            { value: "501k - 2m SEK", label: "501k - 2m SEK" },
            { value: "2-10m SEK", label: "2-10m SEK" },
            { value: "> 10m SEK", label: "> 10m SEK" },
        ],
    },
    numEmployees: {
        key: 'numEmployees',
        type: 'radioGroup',
        required: true,
        order: 10,
        label: "Antal anställda",
        text: "Antal anställda",
        options: [
            { value: "0", label: "0" },
            { value: "1-4", label: "1-4" },
            { value: "5-10", label: "5-10" },
            { value: "> 10", label: "> 10" },
        ],
    },
}

export const CompanyQuestionsEng = {
    compForm: {
        key: 'compForm',
        type: 'radioGroup',
        required: true,
        order: 2,
        label: "Company form",
        text: "Company form",
        options: [
            { value: companyFormChoices["Enskild firma"].value, label: companyFormChoices["Enskild firma"].label },
            { value: companyFormChoices["Handelsbolag/Kommanditbolag"].value, label: companyFormChoices["Handelsbolag/Kommanditbolag"].label},
            { value: companyFormChoices["Aktiebolag"].value, label: companyFormChoices["Aktiebolag"].label },
        ],
    },
    compName: {
        key: 'compName',
        type: 'textField',
        required: true,
        order: 3,
        label: "Company Name",
        text: "Company Name",
        placeHolder: "Enter your company name"
    },
    website: {
        key: 'website',
        type: 'textField',
        required: false,
        order: 1,
        label: "Website",
        text: "Website (if applicable)",
        placeHolder: "i.e: www.youradress.com"
    },
    orgNum: {
        key: 'orgNum',
        type: 'textField',
        order: 4,
        required: true,
        label: "Organization number",
        text: "Organization number",
        placeHolder: "Enter number"
    },
    region: {
        key: 'region',
        keyScania: "county",
        keyOutsideSweden: "country",
        required: true,
        type: 'autoCompleteRegion',
        order: 5,
        label: "Headquarter",
        text: "Region (headquarter if multiple)",
        textScania: "Municipality",
        textOutsideSweden: "Country",
        options: REGION_LIST,
        optionsScania: COUNTY_LIST,
        optionsOutsideSweden: COUNTRY_LIST,
    },
    founded: {
        key: 'founded',
        type: 'datePickerYear',
        required: true,
        order: 6,
        label: "Year founded",
        text: "Year founded",
    },
    compRole: {
        key: 'compRole',
        type: 'textField',
        required: true,
        order: 7,
        label: "Your role in the company",
        text: "Your role in the company",
        placeHolder: "Answer"
    },
    revenue: {
        key: 'revenue',
        type: 'radioGroup',
        required: true,
        order: 9,
        label: "Revenue",
        text: "Revenue (latest full fiscal year)",
        options: [
            { value: "< 500k SEK", label: "< 500k SEK" },
            { value: "501k - 2m SEK", label: "501k - 2m SEK" },
            { value: "2-10m SEK", label: "2-10m SEK" },
            { value: "> 10m SEK", label: "> 10m SEK" },
        ],
    },
    numEmployees: {
        key: 'numEmployees',
        type: 'radioGroup',
        required: true,
        order: 10,
        label: "Number of employees",
        text: "Number of employees",
        options: [
            { value: "0", label: "0" },
            { value: "1-4", label: "1-4" },
            { value: "5-10", label: "5-10" },
            { value: "> 10", label: "> 10" },
        ],
    },
}

export const ExtendedCompanyQuestionsSwe = {
    compOwnership: {
        key: 'compOwnership',
        type: 'compOwnershipCard',
        required: true,
        order: 12,
        label: "Företagets ägarstruktur",
        ownerTypeLabel: "Ägartyp",
        ownerNameLabel: "Namn/Företagsnamn",
        ownershipLabel: "Ägande (%)",
        ownerTimeComittmentLabel: "Tids-engagemang (% av heltid)",
        errorText: "Den totala procentandelen blir inte 100%",
        ownerTypeOptions: [
            { value: "privateFounder", label: "Grundare (privatperson)" },
            { value: "compFounder", label: "Grundare (via bolag)" },
            { value: "investor", label: "Investerare" },
            { value: "privateOther", label: "Övrig (privatperson)" },
            { value: "compOther", label: "Övrig (via bolag)" },
        ]
    },
    compStateAid: {
        key: 'compStateAid',
        type: 'radioGroup',
        required: true,
        order: 13,
        listLabel: "Fått statsstöd",
        label: "Statsstöd",
        text: "Har bolaget tagit emot statsstöd i form av finansiering (lokalt/regionalt/nationellt) under de senaste 3 åren?",
        options: [
            { value: "yes", label: "Ja" },
            { value: "no", label: "Nej" },
        ],
    },
    compStateAidValue: {
        key: 'compStateAidValue',
        type: 'compStateAidCard',
        required: false,
        requiredKey: 'compStateAid',
        requiredKeyValue: 'yes',
        order: 14,
        label: "Statsstöd",
        dateLabel: "Datum",
        grantOrgLabel: "Beviljande organisation",
        amountLabel: "Summa",
    }
}

export const ExtendedCompanyQuestionsEng = {
    compOwnership: {
        key: 'compOwnership',
        type: 'compOwnershipCard',
        required: true,
        order: 12,
        label: "Company Ownership",
        ownerTypeLabel: "Owner Type",
        ownerNameLabel: "Name/Company name",
        ownershipLabel: "Ownership (%)",
        ownerTimeComittmentLabel: "Time commitment (% of full time)",
        errorText: "Total percentage is not adding up to 100%",
        ownerTypeOptions: [
            { value: "privateFounder", label: "Founder (private)" },
            { value: "compFounder", label: "Founder (through company)" },
            { value: "investor", label: "Investor" },
            { value: "privateOther", label: "Other (private)" },
            { value: "compOther", label: "Other (company)" },
        ]
    },
    compStateAid: {
        key: 'compStateAid',
        type: 'radioGroup',
        required: true,
        order: 13,
        listLabel: "Recived state aid",
        label: "State aid",
        text: "Has the company received state aid in the form of financing (locally/regionally/nationally) during the past 3 years?",
        options: [
            { value: "yes", label: "Yes" },
            { value: "no", label: "No" },
        ],
    },
    compStateAidValue: {
        key: 'compStateAidValue',
        type: 'compStateAidCard',
        required: false,
        requiredKey: 'compStateAid',
        requiredKeyValue: 'yes',
        order: 14,
        label: "State aid",
        dateLabel: "Date",
        grantOrgLabel: "Granting organization",
        amountLabel: "Amount",
    }
}