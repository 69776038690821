import { Box, Tab, Tabs } from "@mui/material";
import { a11yProps, setStepVisuals } from "../../../helper/Stepper/setStepVisual";
import { useAuth } from "../../../contexts/AuthContext";
import { useGetStepListData } from "../../../hooks/stepData/useGetStepListData";
import { useEffect } from "react";

import ViewCompactIcon from '@mui/icons-material/ViewCompact';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import { useGetEntryStepInfo } from "../../../hooks/projectData/useGetEntryStepInfo";
import { useGetFirstMeetInfo } from "../../../hooks/projectData/useGetFirstMeetInfo";

export const StepperTabComponentIncubator = ({ projData, value, setValue = null, isPopup = false, isHover = false, onClickStep }) => {
    //const { loading, stepList } = useGetStepListData(projData);

    const { entryStepData } = useGetEntryStepInfo(projData?.id);
    const { firstMeetData } = useGetFirstMeetInfo(projData?.id);

    const { currentUser } = useAuth();

    const setTabValue = () => {
        if (value === 0){
            return false;
        }
        else{
            return value;
        }
    }

    return (
        <Box className="tab-stepper" sx={{
            display: 'flex', justifyContent: 'center',
        }}>
            {isPopup ? (
                <Tabs value={setTabValue()} aria-label="stepper ibo home">
                    {setStepVisuals(entryStepData, "Init", 1, currentUser, onClickStep, isHover)}
                    {setStepVisuals(firstMeetData, "Meeting", 2, currentUser, onClickStep, isHover)}
                    {setStepVisuals(null, "Exit", 3, currentUser, onClickStep, isHover)}
                </Tabs>
            ) : (
                <Tabs value={setTabValue()} aria-label="stepper ibo home">
                    {currentUser.role >= 2 ? (
                        <Tab className="stepper-tab" label={<ViewCompactIcon />} value={0} onClick={() => onClickStep("/matrix")} />
                    ) : (
                        <Tab className="stepper-tab" label={<HomeRoundedIcon />} value={0} onClick={() => { isPopup ? setValue(0) : onClickStep(window.location.pathname) }} />
                    )}
                    {setStepVisuals(entryStepData, "Init", 1, currentUser, onClickStep, isHover)}
                    {setStepVisuals(firstMeetData, "Meeting", 2, currentUser, onClickStep, isHover)}
                    {setStepVisuals(null, "Exit", 3, currentUser, onClickStep, isHover)}
                </Tabs>
            )}
        </Box>
    );
}