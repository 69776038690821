import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../../firebase";


export const useGetOldGrading = (/** @type {String} */ projectID) => {
    const [oldGradeList, setGradeList] = useState([])

    useEffect(() => {
        if (projectID != null && projectID !== "") {
            const getOldGrading = async () => {
                const querySnapshot = await getDocs(query(collection(db, 'Projects/' + projectID + "/Ratings"), orderBy("created", "asc")));
                const getOldGrading = [];
                querySnapshot.forEach((doc) => {
                    if (doc.id !== 'ReadinessLevel') {
                        getOldGrading.push({
                            key: doc.id,
                            ...doc.data(),
                        });
                    }

                });

                setGradeList(getOldGrading);
            }

            getOldGrading();
        }
    }, [projectID]);

    return oldGradeList;
};