import { Box, Tab, Tabs } from "@mui/material";
import { a11yProps, setStepVisuals } from "../../../helper/Stepper/setStepVisual";
import { useAuth } from "../../../contexts/AuthContext";
import { useGetStepListData } from "../../../hooks/stepData/useGetStepListData";
import { useEffect } from "react";

import ViewCompactIcon from '@mui/icons-material/ViewCompact';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';

export const StepperTabComponentStart = ({ projData, value, setValue = null, isPopup = false, isHover = false, onClickStep }) => {
    const { loading, stepList } = useGetStepListData(projData)
    const { currentUser } = useAuth();

    const setTabValue = () => {
        if (value === 0){
            return false;
        }
        else{
            return value;
        }
    }

    return (
        <Box className="tab-stepper" sx={{
            display: 'flex', justifyContent: 'center',
        }}>
            {isPopup ? (
                <Tabs value={setTabValue()} aria-label="stepper ibo home">
                    {setStepVisuals(stepList['a'], "Init", 1, currentUser, onClickStep, isHover)}
                    {setStepVisuals(stepList['meet1'], "Meeting", 2, currentUser, onClickStep, isHover)}
                    {setStepVisuals(stepList['b'], "Link", 3, currentUser, onClickStep, isHover)}
                    {setStepVisuals(stepList['meet2'], "Meeting", 4, currentUser, onClickStep, isHover)}
                    {setStepVisuals(stepList['c'], "Link", 5, currentUser, onClickStep, isHover)}
                    {setStepVisuals(stepList['meet3'], "Meeting", 6, currentUser, onClickStep, isHover)}
                    {setStepVisuals(stepList['d'], "Link", 7, currentUser, onClickStep, isHover)}
                    {setStepVisuals(stepList['meet4'], "Meeting", 8, currentUser, onClickStep, isHover)}
                    {setStepVisuals(stepList['e'], "Link", 9, currentUser, onClickStep, isHover)}
                    {setStepVisuals(stepList['meet5'], "Meeting", 10, currentUser, onClickStep, isHover)}
                    {setStepVisuals(stepList['f'], "Exit", 11, currentUser, onClickStep, isHover)}
                </Tabs>
            ) : (
                <Tabs value={setTabValue()} aria-label="stepper ibo home">
                    {currentUser.role >= 2 ? (
                        <Tab className="stepper-tab" label={<ViewCompactIcon />} value={0} onClick={() => onClickStep("/matrix")} />
                    ) : (
                        <Tab className="stepper-tab" label={<HomeRoundedIcon />} value={0} onClick={() => { isPopup ? setValue(0) : onClickStep(window.location.pathname) }} />
                    )}
                    {setStepVisuals(stepList['a'], "Init", 1, currentUser, onClickStep, isHover)}
                    {setStepVisuals(stepList['meet1'], "Meeting", 2, currentUser, onClickStep, isHover)}
                    {setStepVisuals(stepList['b'], "Link", 3, currentUser, onClickStep, isHover)}
                    {setStepVisuals(stepList['meet2'], "Meeting", 4, currentUser, onClickStep, isHover)}
                    {setStepVisuals(stepList['c'], "Link", 5, currentUser, onClickStep, isHover)}
                    {setStepVisuals(stepList['meet3'], "Meeting", 6, currentUser, onClickStep, isHover)}
                    {setStepVisuals(stepList['d'], "Link", 7, currentUser, onClickStep, isHover)}
                    {setStepVisuals(stepList['meet4'], "Meeting", 8, currentUser, onClickStep, isHover)}
                    {setStepVisuals(stepList['e'], "Link", 9, currentUser, onClickStep, isHover)}
                    {setStepVisuals(stepList['meet5'], "Meeting", 10, currentUser, onClickStep, isHover)}
                    {setStepVisuals(stepList['f'], "Exit", 11, currentUser, onClickStep, isHover)}
                </Tabs>
            )
            }

        </Box >
    );
}