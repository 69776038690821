import { doc, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../../firebase";
import firebaseRefList from "../../firebase/firebaseRefList";

export const useGetCompanyInfo = (projectID) => {
    const [companyInfo, setCompanyInfo] = useState(Object);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (projectID == null) return;
        setIsLoading(true);
        const ref = onSnapshot(doc(db, "Projects/" + projectID + "/CompanyInfo/CompanyInfo"), (querySnapshot) => {
            if (querySnapshot.data() != null) {
                let compData = { id: querySnapshot.id, ...querySnapshot.data() }

                setCompanyInfo(compData)
            }
            setIsLoading(false);
        });

        firebaseRefList.push(ref);
    }, [projectID]);

    return {companyInfo, isLoading};
};