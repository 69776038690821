import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../../firebase";
import firebaseRefList from "../../firebase/firebaseRefList";


export const useGetLatestGrading = (/** @type {String} */ projectID) => {
    const [gradeData, setGradeData] = useState(Object);
    
    useEffect(() => {
        const getLatestGrade = () => {
            const ref = onSnapshot(query(collection(db, 'Projects/' + projectID + "/Ratings"), orderBy("created", "desc")), (querySnapshot) => {
                const getLatestGrade = [];
                querySnapshot.forEach((doc) => {
                    getLatestGrade.push({
                        grade: doc.data().ratings,
                    });
                });

                if (getLatestGrade.length > 0) {
                    setGradeData(getLatestGrade[0].grade);
                }
            });

            firebaseRefList.push(ref);
        }

        if (projectID != null && projectID !== ""){
            getLatestGrade();
        }
    }, [projectID]);

    return gradeData;
}