import { useState, useEffect } from 'react'
import './css/Grading.css'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { db } from '../../firebase';
import { addGradingToProj } from '../../firebase/cloudFunctions';
import CircularProgress from '@mui/material/CircularProgress';
import format from 'date-fns/format'
import firebaseRefList from '../../firebase/firebaseRefList'
import { collection, onSnapshot, orderBy, query } from 'firebase/firestore';
import { Box, Stack, Table, TableBody, TableCell, TableRow } from '@mui/material';
import { GradingRow } from './Components/gradingRow';
import { GRADING_TABLE_HEADERS, GRADING_COLUMNS_TAGS } from '../../constants/grading';
import { AlertBox } from '../../helper/AlertBox';


const Grading = (props) => {
    const required = [
        "CR",
        "TeamR",
        "BR",
        "SR",
        "FR",
    ]

    const levels = [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
    ]

    const [loading, setLoading] = useState(true);
    //const [latestGrade, setLatestGrade] = useState([])
    useEffect(() => {
        const getLatestGrade = () => {
            const ref = onSnapshot(query(collection(db, 'Projects/' + props.projectData.id + "/Ratings"), orderBy("created", "desc")), (querySnapshot) => {
                const getLatestGrade = [];
                querySnapshot.forEach((doc) => {
                    getLatestGrade.push({
                        grade: doc.data().ratings,
                    });
                });

                //setLatestGrade(getLatestGrade)
                setLoading(false);
                if (getLatestGrade.length > 0) {
                    return setBtnSavedClasses(getLatestGrade[0].grade)
                }
            });

            firebaseRefList.push(ref);
        }
        getLatestGrade();

        return () => { setLoading(false) }
    }, [loading, props.projectData.id]);

    //console.log('latest grade: ', latestGrade)
    const [textValue, setTextValue] = useState("");

    const [assignedRatings, setAssignedRatings] = useState([]);
    const [ratings, setRatings] = useState({});

    useEffect(() => {

        const subscriber = () => {
            const ref = onSnapshot(query(collection(db, 'Projects/' + props.projectData.id + "/Ratings"), orderBy("created", "asc")), (querySnapshot) => {
                const getRatingsFromFirebase = [];
                querySnapshot.forEach((doc) => {

                    //console.log("Order: " + doc.data().order);
                    getRatingsFromFirebase.push({
                        ...doc.data(), //spread operator
                        key: doc.id, // `id` given to us by Firebase
                    });
                });

                setAssignedRatings(getRatingsFromFirebase)
                setLoading(false);
            });

            firebaseRefList.push(ref);
        }
        subscriber();

    }, [loading, props.projectData.id]);


    const [btnClasses, setBtnClasses] = useState({})
    const [btnSavedClasses, setBtnSavedClasses] = useState({})
    const [btnClass, setBtnClass] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const [errorInfo, setErrorInfo] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const onTextChange = (e) => setTextValue(e.target.value);


    /**
     * @param {string} tag 
     * @param {number} score 
     */
    function setRating(tag, score) {
        //setBtnSavedClasses("");
        let localRating = ratings;
        if (localRating[tag] === score) {
            delete localRating[tag];
        }
        else {
            localRating[tag] = score;
        }
        setRatings(localRating);
        setBtnClasses(localRating);
        //console.log('set rating: ', ratings);
        setBtnClass(!btnClass);
    }


    const handleSubmit = () => {

        setIsLoading(true);

        addGradingToProj({
            projectID: props.projectData.id,
            gradeName: textValue,
            ratings: ratings,
        }).then(() => {
            setError(false);
            setRatings({})
            setTextValue("")
            setBtnClass(false)
            setBtnClasses({})
            setOpenSnackbar(true);
        }).catch((e) => {
            setError(true);
            setErrorInfo(e);
            setOpenSnackbar(true);
        }).finally(() => {
            setIsLoading(false);
        })
    }

    const setSaveDisabled = () => {
        let isUndefined = false;
        required.forEach((key) => {
            if (ratings[key] == null) {
                isUndefined = true;
            }
        });

        return isUndefined;
    }


    function checkBtnClass(ratings) {

        let same = true;
        Object.keys(ratings).forEach((key) => {
            if (ratings[key] !== btnSavedClasses[key]) {
                same = false;
            }
        });

        if (same) {
            Object.keys(btnSavedClasses).forEach((key) => {
                if (ratings[key] !== btnSavedClasses[key]) {
                    same = false;
                }
            });
        }

        return same;
    }

    if (loading) {
        return <div style={{ display: 'flex', justifyContent: 'center' }}><span><CircularProgress /></span></div>
    }


    return (
        <>
            <div>
                {props?.isEditable !== true ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '-20px', paddingBottom: '5px', fontSize: '16px', fontStyle: 'italic' }}>
                        - Read only -
                    </Box>
                ) : ("")}

                <Table className="grade-table" id="table">
                    <TableBody>
                        <TableRow className="grade-table">
                            <TableCell className="grade-table grade-table-header">
                                {GRADING_TABLE_HEADERS['Level']}
                            </TableCell>
                            <TableCell className="grade-table grade-table-header">
                                {GRADING_TABLE_HEADERS[GRADING_COLUMNS_TAGS.CRTag]}
                            </TableCell>
                            <TableCell className="grade-table grade-table-header">
                                {GRADING_TABLE_HEADERS[GRADING_COLUMNS_TAGS.TeamRTag]}
                            </TableCell>
                            <TableCell className="grade-table grade-table-header">
                                {GRADING_TABLE_HEADERS[GRADING_COLUMNS_TAGS.BRTag]}
                            </TableCell>
                            <TableCell className="grade-table grade-table-header">
                                {GRADING_TABLE_HEADERS[GRADING_COLUMNS_TAGS.SRTag]}
                            </TableCell>
                            <TableCell className="grade-table grade-table-header">
                                {GRADING_TABLE_HEADERS[GRADING_COLUMNS_TAGS.FRTag]}
                            </TableCell>
                            <TableCell className="grade-table grade-table-header">
                                {GRADING_TABLE_HEADERS[GRADING_COLUMNS_TAGS.TechRTag]} (OPTIONAL)
                            </TableCell>
                            <TableCell className="grade-table grade-table-header">
                                {GRADING_TABLE_HEADERS[GRADING_COLUMNS_TAGS.IPRR]} (OPTIONAL)
                            </TableCell>
                        </TableRow>

                        {levels.map((level) => {
                            return (
                                <GradingRow key={'grading-' + level} level={level} setRating={props?.isEditable === true ? setRating : () => { }} btnClasses={btnClasses} btnSavedClasses={btnSavedClasses} isEditable={props?.isEditable} />
                            )
                        })}

                    </TableBody>
                </Table>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: "40px", marginTop: props?.isEditable === true ? "20px" : "opx" }}>
                {isLoading ? (
                    <CircularProgress />
                ) : (
                    <Stack direction={'row'}>
                        {props?.isEditable === true ? (
                            <>
                                <TextField
                                    label="Enter grading name"
                                    size="small"
                                    disabled
                                    onChange={onTextChange}
                                    value={textValue}
                                />

                                <Button size="medium" variant="contained" disabled onClick={handleSubmit}>Save</Button>
                            </>
                        ) : ("")}
                    </Stack>
                )}
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                {assignedRatings.map((item) => (item.ratings != null && checkBtnClass(item.ratings)) ?
                    (
                        <div key={item.name}>
                            <button className="grade-name-button-active" onClick={() => setBtnSavedClasses(item.ratings)}>
                                <span>
                                    <strong>{item.name}</strong>
                                </span>
                                <span style={{ marginLeft: "5px", fontSize: "12px", marginRight: "0px", marginTop: "2px" }}>
                                    ({new Date(item.created.seconds * 1000).toLocaleDateString('se-SV')})
                                </span>
                            </button>
                        </div>
                    ) : (
                        <div key={item.name}>
                            <button className="grade-name-button" onClick={() => setBtnSavedClasses(item.ratings)}>
                                <span>
                                    <strong>{item.name}</strong>
                                </span>
                                <span style={{ marginLeft: "5px", fontSize: "12px", marginRight: "0px", marginTop: "2px" }}>
                                    ({new Date(item.created.seconds * 1000).toLocaleDateString('se-SV')})
                                </span>
                            </button>
                        </div>)

                )}
            </div>

            <AlertBox open={openSnackbar} setOpen={setOpenSnackbar} isError={error} successMsg={"Your grading is saved!"} errorMsg={"Something went wrong!"} errorInfo={errorInfo} />
        </>)
}

export default Grading
