/**
 * 
 * @param {function():void} handleOpenInfo 
 * @param {function():void} handleOpenVideo 
 * @returns 
 */
export const INTRODUCTION_PARAGRAPHS_START = (handleOpenInfo, handleOpenVideo) => {
    return {
        header: <h1 className="h1-mobile">Welcome to the platform for Krinova START!</h1>,
        paragraph1: <p className="content">This platform will guide you through the process of developing and validating your business idea with guidance from your assigned Krinova Business Designer. The platform for Krinova START is designed as an online learning process that will take you through the most important aspects and tools to develop your idea into a business, build a Business Model and get ready to prototype your product or service to test in the market. We expect this process to last 4-6 months, depending on how much time you can dedicate to your idea.</p>,
        paragraph2: <p className="content">The graphics at the top of the page represents your progress, and also serves as the navigation tool to access each step. The circles are checkpoints in your progress, in the form of meetings with your Business Designer. Use these meetings effectively to assess the work done and to discuss challenges and strategies to progress with your idea. The links between the circles indicate your homework to be done in between meetings. It is expected that the assignments are submitted, and approved by your Business Designer, before proceeding to the next stage.{/* More information on how to use this platform and how it will guide you through the process, is available <span onClick={handleOpenInfo} className="open-modal-link" ><strong>here</strong></span>.*/}</p>,
        paragraph3: <p className="content">In the top panel to the right, you can see the date for your next meeting/checkpoint or homework assignment, change the settings for which email notifications to receive, or add other participants to your project.</p>,
        paragraph4: <p className="content">One of the integrated tools that will be used throughout the process is the digital whiteboard service Miro. In case you want to familiarize yourself with its interface and functionality while waiting for your first assignments, you can watch some of their introductory videos <span onClick={handleOpenVideo} className="open-modal-link"><strong>here</strong></span>.</p>,
        paragraph5: <p className="content">Once your Business Designer has shared a link to your Miro board, you can always gain quick access to it in the panel to the right, apart from it showing up at relevant points throughout your homework assignments.</p>,
        paragraph6: <p className="content">Good Luck!</p>,
    }
}

/**
 * 
 * @param {function():void} handleOpenInfo 
 * @param {function():void} handleOpenVideo 
 * @returns 
 */
export const INTRODUCTION_PARAGRAPHS_INCUBATOR = (handleOpenInfo, handleOpenVideo) => {
    return {
        header: <h1 className="h1-mobile">Welcome to the platform for Krinova INCUBATOR!</h1>,
        paragraph1: <p className="content">Your profile on this platform is where you provide your assigned Business Designer the necessary information about yourself, your company and your business idea, as well as making sure it's up to date. To view, or edit, the information you currently have provided, go to your profile by clicking the button in the panel to the right or the Entry-step in the progress graphics above. You will receive an email when it's time for a mandatory periodical review of your information.</p>,
        paragraph2: <p className="content">One of the integrated tools that will be used throughout the Krinova Business Growth process is the digital whiteboard service Miro. In case you want to familiarize yourself with its interface and functionality, you can watch some of their introductory videos <span onClick={handleOpenVideo} className="open-modal-link"><strong>here</strong></span>. Once your Business Designer has shared a link to your Miro board, you can always gain quick access to it in the panel to the right. In this panel you also find the button to invite another participant to your company profile on the platform.</p>,
        paragraph3: <p className="content">Good Luck!</p>,
    }
}

/**
 * 
 * @param {function():void} handleOpenInfo 
 * @param {function():void} handleOpenVideo 
 * @returns 
 */
export const INTRODUCTION_PARAGRAPHS_SCALE = (handleOpenInfo, handleOpenVideo) => {
    return {
        header: <h1 className="h1-mobile">Welcome to the platform for Krinova SCALE!</h1>,
        paragraph1: <p className="content">Your profile on this platform is where you provide your assigned Business Designer the necessary information about yourself, your company and your business idea, as well as making sure it's up to date. To view, or edit, the information you currently have provided, go to your profile by clicking the button in the panel to the right or the Entry-step in the progress graphics above. You will receive an email when it's time for a mandatory periodical review of your information.</p>,
        paragraph2: <p className="content">One of the integrated tools that will be used throughout the Krinova Business Growth process is the digital whiteboard service Miro. In case you want to familiarize yourself with its interface and functionality, you can watch some of their introductory videos <span onClick={handleOpenVideo} className="open-modal-link"><strong>here</strong></span>. Once your Business Designer has shared a link to your Miro board, you can always gain quick access to it in the panel to the right. In this panel you also find the button to invite another participant to your company profile on the platform.</p>,
        paragraph3: <p className="content">Good Luck!</p>,
    }
}

export const SETTINGSTEXT = {
    deadline: "Your next meeting/deadline: ",
    notificationsHeader: "Email notification settings:",
    notificationsLabel: "Choose which optional email notifications you would like to receive.",
    unlocked: "New homework unlocked",
    oneWeek: "Reminder 1 week prior to homework deadline",
    oneDay: "Reminder 1 day prior to homework deadline",
    reviewed: "Homework approved",
    meetingApproved: "Checkpoint (meeting) approved with comments",
    profileLink: "Profile",
    miroLink: "Miro-link",
    noMiroLink: "No Miro-link added",
    addPatricipant: "Invite paticipant"
}