import { Box, Divider, IconButton, List, ListItem, ListItemText, Modal, Stack, Tooltip } from "@mui/material";
import { Fragment, useMemo, useState } from "react";
import AltRouteRoundedIcon from '@mui/icons-material/AltRouteRounded';
import EditIcon from '@mui/icons-material/Edit';

import { useAuth } from "../../../contexts/AuthContext";
import UpdateCompInfo from "../../UpdateData/UpdateCompInfo";
import { StyledListItemEntryStep } from "../../../helper/styledComponents";
import { OwnerTypes, TrackLabels } from "../../../constants/labels/labels";
import { UpdateCompInfo_v2 } from "../../UpdateData/UpdateCompInfo_v2";

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { CompanyQuestionsEng, CompanyQuestionsSwe, ExtendedCompanyQuestionsEng, ExtendedCompanyQuestionsSwe, ProjectInfoQuestionsEng, ProjectInfoQuestionsSwe, ProjectStartQuestionsEng, ProjectStartQuestionsSwe } from "../../../constants/companyQuestions/companyQuestions";
import { OwnershipDisplayComponent } from "../../ExtendedCompanyQuestions/CompanyOwnership/OwnershipDisplayComponent";
import { CompanyStateAidDisplayComponent } from "../../ExtendedCompanyQuestions/CompanyStateAid/CompanyStateAidDisplayComponent";

import OutlinedFlagRoundedIcon from '@mui/icons-material/OutlinedFlagRounded';

export const DisplayCompanyInfo = ({ companyInfo, projectData, updateFrame }) => {
    const { currentUser } = useAuth();

    const [openModalComp, setOpenModalComp] = useState(false);
    const handleModalCompOpen = () => {
        setOpenModalComp(true);
    }

    const handleModalCompClose = () => {
        updateFrame();
        setOpenModalComp(false);
    }

    const projectStartQuestions = useMemo(() => {
        if (currentUser?.userInfo?.prefLang === 'swe') {
            return ProjectStartQuestionsSwe;
        }
        else {
            return ProjectStartQuestionsEng;
        }
    }, [currentUser]);

    const companyQuestions = useMemo(() => {
        if (currentUser?.userInfo?.prefLang === 'swe') {
            if (projectData?.activeTrack === TrackLabels.incubator.key) {
                return { ...CompanyQuestionsSwe, ...ExtendedCompanyQuestionsSwe };
            }
            else {
                return CompanyQuestionsSwe;
            }
        }
        else {
            if (projectData?.activeTrack === TrackLabels.incubator.key) {
                return { ...CompanyQuestionsEng, ...ExtendedCompanyQuestionsEng };
            }
            else {
                return CompanyQuestionsEng;
            }

        }
    }, [currentUser]);

    const projectInfoQuestions = useMemo(() => {
        if (currentUser?.userInfo?.prefLang === 'swe') {
            return ProjectInfoQuestionsSwe;
        }
        else {
            return ProjectInfoQuestionsEng;
        }
    }, [currentUser]);

    const displayCompCity = () => {
        if (companyInfo != null && companyInfo.compCity != null) {
            if (String(companyInfo.compCity).includes(';')) {
                let cityData = String(companyInfo.compCity).split(';');
                if (cityData[0] != null && cityData[0] !== '') {
                    return String(cityData[0] + " - " + cityData[1]);
                }
                else {
                    return String(cityData[1]);
                }
            }
            else {
                return String(companyInfo.compCity);
            }
        }
        else {
            return "Not Set";
        }
    }

    const displayCompanyChoice = () => {
        if (projectData?.existingComp === "Yes, in a newly formed company based on the idea" || projectData?.existingComp === "Yes, within an established company") {
            return true;
        }
        else if (projectData?.existingComp === 'yes_new' || projectData?.existingComp === 'yes_established') {
            return true;
        }
        else {
            return false;
        }
    }


    const displayExistingCompanyAnswer = () => {
        if (projectData?.existingComp === 'no' || projectData?.existingComp === 'No/Not yet') {
            if (currentUser?.userInfo?.prefLang === 'swe') {
                return "Nej/Inte än";
            }
            else {
                return "No/Not yet";
            }
        }
        else {
            return "";
        }
    }

    const displayWebsite = () => {
        if (projectData?.website != null) {
            if (String(projectData?.website).startsWith('http://') || String(projectData?.website).startsWith('https://')) {
                return <a href={projectData?.website} target="_blank">{projectData?.website} <OpenInNewIcon sx={{ height: '18px', marginTop: '-2px' }} /></a>
            }
            else {
                return <a href={'//' + projectData?.website} target="_blank">{projectData?.website} <OpenInNewIcon sx={{ height: '18px', marginTop: '-2px' }} /></a>
            }

        }
        else {
            return "Not Set"
        }
    }

    const displayHeaderText = () => {
        if (projectData?.existingComp === "Yes, in a newly formed company based on the idea" || projectData?.existingComp === "Yes, within an established company") {
            return "Company info:";
        }
        else if (projectData?.existingComp === 'yes_new' || projectData?.existingComp === 'yes_established') {
            return "Company info:";
        }
        else {
            return "Project info:";
        }
    }

    const displayStateAidAnswer = (value) => {
        if (value != null) {
            let option = companyQuestions.compStateAid?.options.find((obj) => obj.value === value);

            if (option != null) {
                return option.label
            }
        }

        return value;
    }

    const highlightAnswer = (key) => {
        if (projectData?.activeTrack === TrackLabels.incubator.key && currentUser.role >= 2) {
            const currentDate = new Date();

            if (key === companyQuestions.founded.key) {
                let foundedDate = new Date();
                foundedDate.setFullYear(companyInfo?.founded);

                if (currentDate.getFullYear() - foundedDate.getFullYear() > 5) {
                    return "Company is older than 5 years.";
                }
            }
            else if (key === companyQuestions.compOwnership.key) {
                let flaggedIssues = [];
                let totalOwnershipFounders = 0;
                let totalCommitment = 0;
                for (let index = 0; index < companyInfo?.compOwnership.length; index++) {
                    const element = companyInfo?.compOwnership[index];

                    if (element.ownerType === 'privateFounder' || element.ownerType === 'compFounder') {
                        totalOwnershipFounders += element.procent;
                        totalCommitment += element.comittment;

                        if (element.comittment == null || element.comittment < 40) {
                            flaggedIssues.push("An owner has a time commitment less than 40% of full time.");
                        }
                    }
                }

                if (totalOwnershipFounders < 75) {
                    flaggedIssues.push("Total ownership for founders is less than 75%.");
                }

                if (totalCommitment < 60) {
                    flaggedIssues.push("Total time commitment for founders is less than 60% of full time.");
                }

                if (flaggedIssues.length > 0) {
                    return flaggedIssues.join(" ");
                }
            }
            else if (key === companyQuestions.compStateAidValue.key) {
                let totalSum = 0;
                let dateDeadline = new Date();
                dateDeadline.setFullYear(dateDeadline.getFullYear() - 3);

                for (let index = 0; index < companyInfo?.compStateAidValue.length; index++) {
                    const element = companyInfo?.compStateAidValue[index];
                    let foundingDate = new Date(element.date);

                    if (foundingDate >= dateDeadline) {
                        element.amount += totalSum;
                    }
                }

                if (totalSum > 300000) {
                    return "Total sum of state aid is more than 300 000 EUR";
                }
            }
        }

        return "";
    }

    return (
        <Fragment>
            <Box sx={{ paddingBottom: '30px' }}>
                {currentUser.role >= 1 ? (
                    <span>
                        <strong>{displayHeaderText()}</strong>

                        <IconButton
                            className="edit-icon-right"

                            onClick={handleModalCompOpen}>

                            <EditIcon style={{ width: "18px", height: "fit-content" }} />
                        </IconButton>
                    </span>
                ) : (
                    <span>
                        <strong>{displayHeaderText()}</strong>
                    </span>
                )}

                <Box>
                    {displayCompanyChoice() ? (
                        <>
                            <ListItem>
                                <StyledListItemEntryStep primary={companyQuestions.compName.label} secondary={companyInfo != null ? companyInfo.compName : "Not Set"} />
                            </ListItem>
                            <Divider />

                            <ListItem>
                                <StyledListItemEntryStep primary={companyQuestions.website.label} secondary={displayWebsite()} />
                            </ListItem>
                            <Divider />

                            <ListItem>
                                <StyledListItemEntryStep primary={companyQuestions.region.label} secondary={displayCompCity()} />
                            </ListItem>
                            <Divider />

                            <ListItem>
                                <StyledListItemEntryStep primary={companyQuestions.compForm.label} secondary={companyInfo != null && companyInfo.compForm != null ? companyInfo.compForm : "Not answered"} />
                            </ListItem>
                            <Divider />

                            <ListItem>
                                <StyledListItemEntryStep primary={companyQuestions.orgNum.label} secondary={companyInfo != null && companyInfo.orgNum != null ? companyInfo.orgNum : "Not answered"} />
                            </ListItem>
                            <Divider />

                            <ListItem>
                                {highlightAnswer(companyQuestions.founded.key) !== "" ? (
                                    <Tooltip title={highlightAnswer(companyQuestions.founded.key)} arrow placement="top">
                                        <OutlinedFlagRoundedIcon sx={{ marginLeft: '-40px', marginRight: '25px', }} fontSize="small" htmlColor="#db0000" />
                                    </Tooltip>
                                ) : ("")}
                                <StyledListItemEntryStep primary={companyQuestions.founded.label} secondary={companyInfo != null && companyInfo.founded != null ? companyInfo.founded : "Not answered"} />
                            </ListItem>
                            <Divider />

                            <ListItem>
                                <StyledListItemEntryStep primary={companyQuestions.compRole.label} secondary={companyInfo != null && companyInfo.compRole != null ? companyInfo.compRole : "Not answered"} />
                            </ListItem>
                            <Divider />

                            <ListItem>
                                <StyledListItemEntryStep primary={companyQuestions.revenue.label} secondary={companyInfo != null && companyInfo.revenue != null ? companyInfo.revenue : "Not answered"} />
                            </ListItem>
                            <Divider />

                            <ListItem>
                                <StyledListItemEntryStep primary={companyQuestions.numEmployees.label} secondary={companyInfo != null && companyInfo.numEmployees != null ? companyInfo.numEmployees : "Not answered"} />
                            </ListItem>
                            <Divider />

                            {companyInfo != null && companyInfo.compOwnership != null && companyInfo.compOwnership.length > 0 && companyQuestions?.compOwnership != null ? (
                                <>
                                    <Box sx={{ paddingX: '15px', paddingTop: '15px' }}>
                                        {highlightAnswer(companyQuestions.compOwnership.key) !== "" ? (
                                            <Tooltip title={highlightAnswer(companyQuestions.compOwnership.key)} arrow placement="top">
                                                <Box sx={{ marginLeft: '10px' }}>
                                                    <OutlinedFlagRoundedIcon sx={{ marginLeft: '-40px', marginRight: '25px', }} fontSize="small" htmlColor="#db0000" />
                                                </Box>
                                            </Tooltip>
                                        ) : ("")}
                                        {companyQuestions.compOwnership.label}
                                    </Box>
                                    <OwnershipDisplayComponent answerData={companyInfo.compOwnership} listVariant />

                                    <Divider />
                                </>
                            ) : ("")}

                            {companyInfo != null && companyInfo.compStateAid != null && companyQuestions?.compStateAid != null? (
                                <>
                                    <ListItem>
                                        <StyledListItemEntryStep primary={companyQuestions.compStateAid.listLabel} secondary={companyInfo != null && displayStateAidAnswer(companyInfo.compStateAid) != null ? displayStateAidAnswer(companyInfo.compStateAid) : "Not answered"} />
                                    </ListItem>
                                    <Divider />
                                </>
                            ) : ("")}

                            {companyInfo != null && companyInfo.compStateAid === 'yes' && companyInfo.compStateAidValue != null && companyQuestions?.compStateAidValue != null ? (
                                <>
                                    <Box sx={{ paddingX: '15px', paddingTop: '15px' }}>
                                        {highlightAnswer(companyQuestions.compStateAidValue.key) !== "" ? (
                                            <Tooltip title={highlightAnswer(companyQuestions.compStateAidValue.key)} arrow placement="top">
                                                <Box sx={{ marginLeft: '10px' }}>
                                                    <OutlinedFlagRoundedIcon sx={{ marginLeft: '-40px', marginRight: '25px', }} fontSize="small" htmlColor="#db0000" />
                                                </Box>
                                            </Tooltip>
                                        ) : ("")}
                                        {companyQuestions.compStateAidValue.label}
                                    </Box>
                                    <CompanyStateAidDisplayComponent answerData={companyInfo.compStateAidValue} listVariant />
                                    <Divider />
                                </>
                            ) : ("")}
                        </>
                    ) : (
                        <>
                            <ListItem>
                                <StyledListItemEntryStep primary={projectInfoQuestions.projName.label} secondary={projectData.value != null ? projectData.value : "Not Set"} />
                            </ListItem>
                            <Divider />

                            <ListItem>
                                <StyledListItemEntryStep primary={projectInfoQuestions.website.label} secondary={displayWebsite()} />
                            </ListItem>
                            <Divider />

                            <ListItem>
                                <StyledListItemEntryStep primary={projectStartQuestions.existingCompNewIdea.label} secondary={displayExistingCompanyAnswer()} />
                            </ListItem>
                            <Divider />
                        </>
                    )}
                </Box>
            </Box>

            <Modal
                open={openModalComp}
                onClose={handleModalCompClose}
            >
                <UpdateCompInfo_v2 projectData={projectData} compInfo={companyInfo} closeModal={handleModalCompClose} />
            </Modal>
        </Fragment>
    )
};