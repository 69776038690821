import { useEffect, useState } from 'react'
import { CircularProgress, Stack, Box } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { getAnalytics, logEvent } from 'firebase/analytics'
import useGetUserProject from '../../hooks/userData/useGetUserProject'
import { useAuth } from '../../contexts/AuthContext'
import { CompleteProjectInfoIncubator } from '../UpdateData/CompleteProjectInfoIncubator'

const CompletionIncubatorPage = () => {
    const { project, loading } = useGetUserProject();
    const [update, setUpdate] = useState(false);
    const { currentUser } = useAuth();
    const [pageLoading, setPageLoading] = useState(true);
    const analytics = getAnalytics();
    const navigate = useNavigate();

    useEffect(() => {
        if (loading === false) {
            console.log("Data. ", project.data)
            if (project.data.isActive != null && project.data.isActive !== true) {
                navigate("/inactive", { replace: true });
            }
            else if (project?.data?.needsCompletion == null || project?.data?.needsCompletion === false || project?.data?.approvedData === true) {
                navigate("/home", { replace: true });
            }

            setPageLoading(false);
        }
    }, [loading, project, update])

    useEffect(() => {
        logEvent(analytics, 'page_view', {
            page_title: 'Completetion Incubator',
            page_path: window.location.pathname,
        });
    }, [])

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Stack justifyContent="center" alignItems="center" spacing={2} sx={{ marginTop: '50px' }}>
                {pageLoading ? (
                    <Box>
                        <CircularProgress />
                    </Box>
                ) : (
                    <>
                        {currentUser?.userInfo?.prefLang === 'swe' ? (
                            <>
                                <Box>
                                    <h5>Komplettera och bekräfta nödvändiga uppgifter för att ansöka till INCUBATOR-programmet</h5>
                                </Box>
                            </>
                        ) : (
                            <>
                                <Box>
                                    <h5>Complete and confirm the necessary information in order to enroll in the INCUBATOR program</h5>
                                </Box>
                            </>
                        )}

                        <CompleteProjectInfoIncubator projectData={project} update={update} setUpdate={setUpdate} />
                    </>
                )}

            </Stack>

        </Box>
    )
}

export default CompletionIncubatorPage;