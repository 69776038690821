import { Box, Divider, FormLabel, Grid, IconButton, InputAdornment, OutlinedInput, Stack, Tooltip } from "@mui/material";
import { useEffect, useMemo, useState } from "react"
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useAuth } from "../../../contexts/AuthContext";
import { ExtendedCompanyQuestionsEng, ExtendedCompanyQuestionsSwe } from "../../../constants/companyQuestions/companyQuestions";
import { CompanyStateAidRow } from "./CompanyStateAidRow";
import { addThousandsSeparator } from "../../Extrafunctions/addThousandSeparator";

export const CompanyStateAidCard = ({ value, handleChangeStateAid, isStateAidInvalid, setStateAidInvalid }) => {
    const { currentUser } = useAuth();
    const [edited, setEdited] = useState(false);
    const [grantList, setGrantList] = useState([{
        date: '',
        grantingOrg: '',
        amount: 0,
    }]);


    const CompStateAidQuestion = useMemo(() => {
        if (currentUser?.userInfo?.prefLang === 'swe') {
            return ExtendedCompanyQuestionsSwe.compStateAidValue;
        }
        else {
            return ExtendedCompanyQuestionsEng.compStateAidValue;
        }
    }, [currentUser]);

    useEffect(() => {
        if (value != null && value.length > 0) {
            setGrantList(value);
        }
    }, [value])

    useEffect(() => {
        let emptyFields = false;
        for (let index = 0; index < grantList.length; index++) {
            const element = grantList[index];
            if (element.date == null || element.date === '' || element.grantingOrg == null || element.grantingOrg === '' || element.amount == null || element.amount <= 0) {
                emptyFields = true;
                break;
            }
        }

        setStateAidInvalid(emptyFields);

        if (edited === true) {
            handleChangeStateAid(grantList);
        }
    }, [grantList]);

    const handleChangeDate = (index, value) => {
        let local = [...grantList];

        console.log(value);

        local[index] = { ...local[index], date: value };

        setGrantList(local);
        setEdited(true);
    }

    const handleChangeGrantOrg = (index, value) => {
        let local = [...grantList];

        local[index] = { ...local[index], grantingOrg: value };

        setGrantList(local);
        setEdited(true);
    }

    const handleChangeAmount = (index, value) => {
        if (isNaN(Number(value)) === false) {
            let local = [...grantList];

            if (Number(value) < 0) {
                local[index] = { ...local[index], amount: 0 };
            }
            else {
                local[index] = { ...local[index], amount: Number(value) };
            }

            setGrantList(local);
            setEdited(true);
        }
    }

    const addRow = () => {
        setGrantList(prev => [...prev, {
            date: '',
            grantingOrg: '',
            amount: 0,
        }]);
        setEdited(true);
    }

    const deleteRow = (index) => {
        let list = [...grantList];
        list.splice(index, 1);
        setGrantList(list);
        setEdited(true);
    }


    const calcTotal = () => {
        let totalSum = 0;

        for (let index = 0; index < grantList.length; index++) {
            const item = grantList[index];

            totalSum += item.amount;
        }

        return totalSum;
    }

    return (
        <Grid item xs={12} sm={12}>
            <FormLabel sx={{ paddingBottom: '5px' }}>{CompStateAidQuestion.label}</FormLabel>

            <Stack spacing={0.5} sx={{ width: '100%' }}>
                {grantList.map((item, index) => (
                    <CompanyStateAidRow
                        key={index}
                        item={item}
                        index={index}
                        handleChangeDate={handleChangeDate}
                        handleChangeGrantOrg={handleChangeGrantOrg}
                        handleChangeAmount={handleChangeAmount}
                        deleteRow={() => deleteRow(index)}
                        isOwnershipInvalid={isStateAidInvalid}
                    />
                ))}

                <Grid container spacing={0.5} sx={{ width: '100%', mb: '5px' }} justifyContent={'space-between'} alignItems={'center'} direction={'row'}>
                    <Grid item xs={3.5}>
                        <IconButton size="small" onClick={addRow}>
                            <AddCircleIcon htmlColor="#4eacc1" />
                        </IconButton>
                    </Grid>

                    <Grid item xs={5.5}>

                    </Grid>

                    <Grid item xs={3}>
                        <Stack>
                            <Box>
                                <Divider className="blue thick" />
                            </Box>
                            <Tooltip arrow title={calcTotal() !== 100 ? CompStateAidQuestion.errorText : ""}>
                                <span>
                                    <OutlinedInput
                                        endAdornment={<InputAdornment position="end">EUR</InputAdornment>}
                                        size="small"
                                        value={addThousandsSeparator(calcTotal())}
                                        disabled
                                        sx={{
                                            "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: calcTotal() <= 0 ? "red" : "gray",
                                            },
                                        }}
                                    />
                                </span>
                            </Tooltip>
                        </Stack>
                    </Grid>

                    <Grid item xs={0.5}>
                        <div style={{ width: '25px' }}></div>
                    </Grid>

                </Grid>
            </Stack>
        </Grid>
    )
}